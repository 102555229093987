//Theme Default

$primary-color: #70E000;
$secondary-color: #8DBF94;
$error-color: #ED2D2D;
$success-color: #00ba88;
$warning-color: #FFE5B3;
$gradient-primary-color: linear-gradient(112.38deg, #00923F -2.36%, #50E992 93.39%);
$gradient-secondary-color: linear-gradient(112.07deg, #004cdf 2.13%, #60d9f3 99.26%);
$gradient-accent-color: linear-gradient(112.07deg, #ed2d2d 2.13%, #f6c566 99.26%);

//Theme Light

$primary-color-light: #DBFFEB;
$secondary-color-light: #B1E5B9;
$error-color-light: #FDD5D5;
$success-color-light: #99E3CF;
$warning-color-light: #fde5b3;
$gradient-primary-color-light: #6694ec;
$gradient-secondary-color-light: #60d9f3;
$gradient-accent-color-light: #f6c566;

//Theme Dark

$primary-color-dark: #007332;
$secondary-color-dark: #719976;
$error-color-dark: #BE2424;
$success-color-dark: #00956D;
$warning-color-dark: #926e26;
$gradient-primary-color-dark: #004cdf;
$gradient-secondary-color-dark: #004cdf;
$gradient-accent-color-dark: #ed2d2d;

$theme-colors: (
  "primary": $primary-color,
  "danger": $error-color,
);

//Text Body

$text-title-color:  #14142A;
$text-body-color: #4E4B66;
$text-label-color: #6E7191;
$text-placeholder-color: #A0A3BD;

// Background

$background-color: #F7F7FC;
$line-color: #D9DBE9;
$off-white-color: #FCFCFC;
$input-background-color: #EFF0F6;


@import "node_modules/bootstrap/scss/bootstrap";

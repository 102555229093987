.cancel {
  > div {
    padding-top: 15px;
  }
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: green;
}

.add-gridtable-service {
  height: 75vh;
  .rgt-container {
    grid-template-columns: 30px 1fr auto !important;

    .rgt-cell {
      min-height: 30px !important;
    }
    .rgt-row-odd {
      background-color: #767676 !important;
    }
    .rgt-even-odd {
      border-radius: 8px !important;
      background-color: #6f6f72 !important;
    }
    .rgt-cell-checkbox {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    .field-description {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 0px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 10px !important;
          padding-right: 30px !important;
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
          padding-right: 30px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
          padding-right: 40px !important;
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          padding-left: 10px !important;
          padding-right: 40px !important;
        }
      }

      &:nth-child(6) {
        .rgt-cell-header-inner {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.add-gridtable-filter {
  @include mobile {
    // height: 35vh;
  }
  .rgt-container {
    @include mobile {
      // max-height: 35vh !important;
      // grid-template-columns: 1fr 1fr 1fr 1fr 90px auto !important;
    }

    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 0px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 10px !important;
          padding-right: 30px !important;
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
          padding-right: 30px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
          padding-right: 40px !important;
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          padding-left: 10px !important;
          padding-right: 40px !important;
        }
      }

      &:nth-child(6) {
        .rgt-cell-header-inner {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.service-upper-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .service-img-placement {
    align-items: center;
    position: relative !important;
    justify-content: center !important;

    display: flex !important;

    img {
      width: 85%;
    }

    > div {
      margin: 0px !important;
    }

    > div > section {
      min-width: 100% !important;
      min-height: 300px !important;

      @include mobile {
        min-width: 107% !important;
      }
    }

    > div > section > div {
      min-height: 270px !important;
    }

    > div > section > div > img {
      // min-width: auto !important;
      // min-height: 260px !important;
      max-width: 100% !important;
      max-height: 260px !important;
    }
  }
}

.field-actions-budget {
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 48px;

  > div {
    margin: 0px !important;
  }

  @include mobile {
    margin: 5px;
  }
}

.field-time-budget {
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 48px;

  @include mobile {
    width: 70px;
  }

  > div {
    margin: 0px !important;
  }

  @include mobile {
    margin: 5px;
  }
}

.gridtable-hour-add-service {
  width: 100%;
  min-height: 25px !important;
  > div > div > div {
    padding: 0px;
    height: 25px !important;
  }
}

.gridtable-value-add-service {
  width: 100%;
  max-height: 25px;
  min-height: 25px;
  > div > input {
    height: 25px;
  }
}

.gridtable-total-add-service {
  width: 100%;
  min-height: 25px;
  > div > input {
    height: 25px;
  }
}

.add-service-modal {
  @include mobile {
    overflow-y: auto !important;
  }
}

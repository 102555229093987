.vehicle-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: hidden;

  .vehicle-info {
    display: flex;
    padding: 20px 25px;
    width: 100%;
    justify-content: end;
  }

  .vehicle-body {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    border-radius: 16px;
    padding: 10px;

    @include mobile {
      height: calc(100% - 40px);
    }
  }
}

.new-table-button {
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px;

  @include mobile {
    span {
      display: none;
    }
  }
}

.field-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field-actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.default-table-vehicle {
  .rgt-wrapper {
    border: none;

    .rgt-header-container {
      background-color: transparent;
      border: none;

      .rgt-search-label,
      .rgt-search-icon,
      .rgt-columns-manager-wrapper {
        color: white;
      }

      .rgt-columns-manager-popover-title {
        color: #67676a;
      }

      .rgt-search-input {
        background-color: $line-color;
        padding: 1px 10px;
        border-radius: 8px;
        color: #5e5e66;
        margin-right: 0px;
      }
    }

    .rgt-container {
      background-color: transparent;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .rgt-row-odd {
        background-color: #767676 !important;
      }
      .rgt-row-even {
        background-color: #565659 !important;
      }
      .rgt-cell-header-actions {
        background-color: #67676a;
        border-bottom-right-radius: 16px;
      }
      .rgt-cell-actions {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .rgt-cell-header-model {
        background-color: #67676a;
        border-bottom-left-radius: 16px;
      }
      .rgt-cell-model {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &::-webkit-scrollbar {
        // display: none;
        width: 10px;
        background-color: #5e5e66;
      }

      &::-webkit-scrollbar-thumb {
        // background-color: #5E5E66;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: #5e5e66;
        border-radius: 15px;
      }
      .rgt-cell {
        min-height: 35px;
      }
      .rgt-cell-header {
        border: none;

        .rgt-cell-header-inner {
          background-color: #5e5e66;
          border: none;

          .rgt-text-truncate {
            color: $line-color;
            padding: 0px 0px;
            font-weight: 500;
            text-transform: uppercase !important;
          }
        }

        &:first-child {
          .rgt-cell-header-inner {
            border-top-left-radius: 16px !important;
            border-bottom-left-radius: 16px !important;
          }
        }

        &:nth-child(2) {
          .rgt-cell-header-inner {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            padding: 0px;
          }
        }

        &:nth-child(3) {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
            padding: 0px 9px;
          }
        }

        // &:nth-child(4) {
        //     .rgt-cell-header-inner {
        //         border-top-right-radius: 30px;
        //         border-bottom-right-radius: 30px;
        //         padding: 0px 9px;
        //     }
        // }
      }

      .rgt-cell {
        background-color: transparent;
        border: none;

        .rgt-text-truncate {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase !important;
        }
      }
    }

    .rgt-footer {
      background-color: transparent;
      border: none;

      .rgt-footer-items-information,
      .rgt-footer-right-container {
        color: white;
      }

      .rgt-footer-page-size {
        align-items: center;
      }
    }
  }

  .icon-action {
    color: black !important;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.trash {
      background-color: #ffffff;
      color: #e82314 !important;

      &:hover &:focus {
        // background-color: $error-color-dark;
      }
    }

    &.send {
      height: 22px;
    }

    &:hover,
    &:focus {
      // background-color: #5E5E66;
      // opacity: 0.5;
    }
  }

  .rgt-footer-pagination-button {
    box-shadow: none;
    background-color: $primary-color-dark !important;
  }

  .rgt-disabled-button {
    background-color: $secondary-color-dark !important;
  }

  .rgt-search-highlight {
    background-color: rgba(219, 255, 235, 0.3);
  }

  .rgt-container-overlay {
    display: flex;
    text-align: center;
  }

  .image-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .not-image {
      width: 100%;
      height: 100%;
      background-color: #5e5e66;
    }
  }
}

.vehicle-table {
  padding: 0px;

  > div {
    > div {
      justify-content: flex-end;
      padding-top: 0.9vh;
      padding-bottom: 0.1vh;
      display: flex;

      > label {
        display: flex;
        flex: 1;
        margin-bottom: 0px;
        align-items: center;
      }
    }
  }
}

// .rgt-text-truncate {
//     padding: 10px 10px !important;
// }

.admin-screen-main {
  > div {
    height: 100%;
  }
}

// @media screen and (max-width: 600px) {
//     .rgt-container {
//         grid-template-columns: 50px 1fr 1fr 70px auto !important
//     }
// }

// .gridtable-for-vehicle {
//     .rgt-container {
//         grid-template-columns: 50px 1fr 1fr 70px auto !important
//     }
// }

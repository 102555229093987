.card-header {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    flex-direction: row;
    align-items: baseline;
    border-bottom: 0px;
    // max-width: 50px;
}

.icon-header {
    cursor: pointer;
    color: $text-label-color !important;
}

.title {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
}

.icon-container {
    width: 35px;
    height: 35px;
    display: flex;
    border: 2px #70e000 solid;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-left: 6px;
    // margin-top: 23px;
}
.icon-container-open {
    width: 35px;
    height: 35px;
    display: flex;
    border: 2px #70e000 solid;
    background-color: #70e000;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-left: 6px;
    // margin-top: 23px;
}

.modules {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    margin-top: -19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-text-open {
    margin-bottom: 0;
    margin-left: 24px;
    color: #70e000 !important;
    font-weight: 600;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    flex-shrink: 1;
}

.icon-text {
    margin-bottom: 0;
    margin-left: 24px;
    color: white !important;
    font-weight: 600;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    flex-shrink: 1;
}

.perfil-text {
    font-family: "poppins";
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
    margin-inline: 15px;
}

.perfil-icon {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
}
.small {
    height: 25px !important;
    width: 19px !important;
    color: #70e000;
}

.small-open {
    height: 25px !important;
    width: 19px !important;
    color: white;
}

.dropdown-menu-logout {
    // display: flex;
    background: transparent;
    margin-left: 90px !important;
    width: 100%;
    border: 0;
    flex-direction: column;

}
.drop-text {
    // margin-bottom: 40px;
    // margin-left: 65px;
    color: white ;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    &:hover {
        color: #70e000;
    }
}

.drop-hover {
    margin-bottom: 40px;
    padding-left: 80px;
    width: 100%;
    background-color: transparent !important;
    &:hover {
        background-color: transparent;
        opacity: 0.7;
        color: #70e000;
    }
}

.dropdown-menu-logout {
    position: absolute !important;
    top: 10px !important;
    right: 0px !important;
    z-index: 999999;

    .dropdown-item {
        &:active {
            background-color: #70e000 !important;
        }
    }
}

@media (max-width: 400px) {
    .dropdown-menu-logout {
        top: 40px !important;
        right: 0px !important;
        left: initial !important;
    }
}

.react-datepicker {
  z-index: 999999 !important;
  color: white !important;
  background-color: #67676a !important;

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #5e5e66 !important;

      .react-datepicker__current-month {
        color: white;
      }

      .react-datepicker__day-name {
        color: white;
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day--outside-month {
          background-color: #00000049 !important;
          border-radius: 0.3em;
        }

        .react-datepicker__day--in-selecting-range {
          background-color: #70e0005e;
        }

        .react-datepicker__day:hover {
          background-color: $primary-color !important;
        }

        .react-datepicker__day--keyboard-selected {
          background-color: $primary-color !important;
        }

        .react-datepicker__day--in-range {
          background-color: #70e0005e;
        }
      }
    }
  }

  .react-datepicker__day {
    color: white !important;
  }
}

div[class*="-indicatorContainer"] {
  padding: 4px !important;
}

.custom-header-select {
  padding: 0px 0px 0px 10px;
  width: 100%;

  @include mobile {
    padding: 10px 0px 0px 0px;
  }

  >div>div>div>div {
    align-content: center;
    // min-height: 31.99px;
    // height: 10px !important;
  }

  .mb-3 {
    margin: 0px !important;

    .div[class*="-control"] {
      height: 10px !important;
    }
  }

  div[class*="-control"] {
    box-shadow: 0 0 0 1px rgb(217, 219, 233);
    border-color: rgb(217, 219, 233);
    background-color: rgb(217, 219, 233);
    min-height: 31.99px !important;
    height: 31.99px !important;

    div[class*="-placeholder"] {
      color: black !important;
    }
  }

  div[class*="-control:hover"] {
    border: none !important;
    border-color: #00000000;
  }

  div[class*="-indicatorContainer"] {
    >svg {
      color: black !important;
    }
  }

  div[class*="-menu"] {
    background-color: rgb(217, 219, 233);
    margin-top: 10px !important;
  }

  div[class*="-NoOptionsMessage"] {
    color: black;
  }

  div[class*="-ValueContainer"] {
    height: 31.99px !important;
  }

  div[class*="-MenuList"] {
    background-color: #67676a !important;
    color: white !important;
  }

  div[class*="-option"] {
    background-color: #67676a !important;
    color: white !important;
  }
}

.header-mobile {
  padding: 10px 10px 10px 20px;
  background: #67676a;
  width: 100%;
  align-items: center;

  @include mobile {
    padding: 10px 0px 10px 0px;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .custom-header-select div[class*="-control"] {
    border-radius: 8px;
  }
}

.mobile-date-picker {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;

  @include mobile {
    flex-direction: column;

    >div>div {
      background-color: #67676a;
      height: 32px;
    }
  }
}
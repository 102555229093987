.aesthetic-quantity-input {
  width: 70px;
}

.aesthetic-inputs-group {
  display: flex;
  width: 100%;
  padding-top: 0px;

  > div {
    margin: 0px !important;
  }
}

.aesthetic-form-group {
  @media (max-width: 1023px) {
    min-width: 150px;
  }

  @media (min-width: 1024px) {
    min-width: 275px;
  }
}

.aesthetic-input-mt {
  @media (max-width: 764px) {
    padding-top: 10px;
  }
  @media (min-width: 1024px) {
  }
}

.responsive-padding-aesthetic {
  margin-bottom: 0;

  @media (max-width: 763px) {
    margin-bottom: 64px;
  }
}

.text-area-min {
  min-height: 773px !important;
}

@media screen and (max-width: 600px) {
  .text-area-min {
    min-height: 623px !important;
  }
}

.delete {
  background-color: red !important;
  margin-left: 0px !important;
  margin-top: 10px !important;
  align-self: flex-end;
}

.client-origin-text {
  color: white;
  font-weight: 500;
  margin-bottom: 0.5em;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  display: inline-block;
  margin-left: 5px;

  @include mobile {
    margin-left: 10px;
  }
}

.select-creatable {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: normal;

  > div > div {
    border: none !important;
    // border-color: #67676a !important;
    color: white;
    background-color: rgb(103, 103, 106);
    box-shadow: transparent !important;

    div[class*="-option"]:hover {
      background-color: rgba(112, 224, 0, 0.05);
    }

    div[class*="-option"]:active {
      background-color: rgba(112, 224, 0, 0.1);
    }

    input {
      text-transform: uppercase;
      color: white !important;
      width: 300px !important;
    }

    > div > div {
      color: white;
    }
  }

  @include mobile {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@include mobile {
  .text-area-min {
    min-height: 623px !important;
  }
}

.client-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;

  .client-info {
    display: flex;
    padding: 20px 25px;
    width: 100%;
    justify-content: end;
  }

  .new-client-button {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    border-radius: 20px;

    @include mobile {
      span {
        display: none;
      }
    }
  }

  .client-body {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    border-radius: 16px;
    padding: 10px;

    @include mobile {
      height: calc(100% - 40px);
    }
  }
}
.non-editable {
  .rgt-wrapper {
    border: none;
    // max-height: 300px;

    .rgt-header-container {
      background-color: transparent;
      border: none;

      .rgt-search-label,
      .rgt-search-icon,
      .rgt-columns-manager-wrapper {
        color: white;
      }

      .rgt-columns-manager-popover-title {
        color: #67676a;
      }

      .rgt-search-input {
        background-color: $line-color;
        padding: 1px 10px;
        border-radius: 8px;
        color: #5e5e66;
        margin-right: 0px;
      }
    }

    .rgt-container {
      background-color: transparent;
      overflow-y: auto;
      -ms-overflow-style: none;
     // scrollbar-width: none;
      grid-template-columns: 1fr 220px auto !important;
      @include mobile {
        grid-template-columns: 1fr auto !important;
      }

      &::-webkit-scrollbar {
        // display: none;
        // width: 0px;
        // background-color: #70e000;
      }
      .rgt-row-odd {
        background-color: #767676 !important;
      }
      .rgt-row-even {
        background-color: #565659 !important;
      }
      .rgt-cell-header-actions {
        background-color: #67676a;
        border-bottom-right-radius: 16px;
      }
      .rgt-cell-actions {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .rgt-cell-header-name {
        background-color: #67676a;
        border-bottom-left-radius: 16px;
      }
      .rgt-cell-cellphone {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .rgt-cell-name {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @include mobile {
          border-radius: 8px;
        }
      }
      &::-webkit-scrollbar-thumb {
        // background-color: #5E5E66;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: #5e5e66;
        border-radius: 15px;
      }

      .rgt-cell-header {
        border: none;

        .rgt-cell-header-inner {
          background-color: #5e5e66;
          border: none;

          .rgt-text-truncate {
            color: $line-color;
            padding: 0px 0px;
            font-weight: 500;
            text-transform: uppercase !important;
          }
        }

        &:first-child {
          // padding-left: 20px;

          .rgt-cell-header-inner {
            border-top-left-radius: 16px !important;
            border-bottom-left-radius: 16px !important;
            // padding: 20px;
          }
        }

        &:nth-child(2) {
          .rgt-cell-header-inner {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            padding-left: 20px;
          }
        }

        &:nth-child(3) {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
            padding: 0px 9px;
          }
        }

        @include mobile {
          &:nth-child(1) {
            .rgt-cell-header-inner {
              border-top-right-radius: 16px !important;
              border-bottom-right-radius: 16px !important;
              padding-left: 20px;
            }
          }

          &:nth-child(2) {
            .rgt-cell-header-inner {
              border-top-right-radius: 16px !important;
              border-bottom-right-radius: 16px !important;
              // padding: 0px;
            }
          }
        }
      }

      .rgt-cell {
        background-color: transparent;
        border: none;

        .rgt-text-truncate {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase !important;

          @include mobile {
            font-size: 12px;
          }
        }
      }

      .rgt-cell-actions {
        > div {
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: center !important;
        }

        > div > svg {
          color: black;
        }
      }
    }

    .rgt-footer {
      background-color: transparent;
      border: none;

      .rgt-footer-items-information,
      .rgt-footer-right-container {
        color: white;
      }

      .rgt-footer-page-size {
        align-items: center;
      }
    }
  }

  .icon-client {
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.trash {
      background-color: #ffaaa4;
      color: #e82314;
    }

    &:hover,
    &:focus {
      background-color: #5e5e66;
    }
  }
}
.default-table-client {
  .rgt-wrapper {
    border: none;
    // max-height: 300px;

    .rgt-header-container {
      background-color: transparent;
      border: none;

      .rgt-search-label,
      .rgt-search-icon,
      .rgt-columns-manager-wrapper {
        color: white;
      }

      .rgt-columns-manager-popover-title {
        color: #67676a;
      }

      .rgt-search-input {
        background-color: $line-color;
        padding: 1px 10px;
        border-radius: 8px;
        color: #5e5e66;
        margin-right: 0px;
      }
    }

    .rgt-container {
      background-color: transparent;
      overflow-y: auto;
      -ms-overflow-style: none;
     // scrollbar-width: none;
      grid-template-columns: 1fr 1fr 85px auto !important;

      &::-webkit-scrollbar {
        // display: none;
        width: 10px;
        background-color: #f3f3f3;
      }
      .rgt-row-odd {
        background-color: #767676 !important;
      }
      .rgt-row-even {
        background-color: #565659 !important;
      }
      .rgt-cell-header-actions {
        background-color: #67676a;
        border-bottom-right-radius: 16px;
      }
      .rgt-cell-actions {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .rgt-cell-header-name {
        background-color: #67676a;
        border-bottom-left-radius: 16px;
      }
      .rgt-cell-name {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        // background-color: #5E5E66;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: #5e5e66;
        border-radius: 15px;
      }
      .rgt-cell {
        min-height: 35px;
      }
      .rgt-cell-header {
        border: none;

        .rgt-cell-header-inner {
          background-color: #5e5e66;
          border: none;

          .rgt-text-truncate {
            color: $line-color;
            padding: 0px 0px;
            font-weight: 500;
            text-transform: uppercase !important;
          }
        }

        &:first-child {
          // padding-left: 20px;

          .rgt-cell-header-inner {
            border-top-left-radius: 16px !important;
            border-bottom-left-radius: 16px !important;
            // padding: 20px;
          }
        }

        &:nth-child(2) {
          .rgt-cell-header-inner {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            padding-left: 20px;
          }
        }

        &:nth-child(3) {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
            padding: 0px 9px;
          }
        }

        @include mobile {
          &:nth-child(1) {
            .rgt-cell-header-inner {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
              padding-left: 20px;
            }
          }

          &:nth-child(2) {
            .rgt-cell-header-inner {
              border-top-right-radius: 16px !important;
              border-bottom-right-radius: 16px !important;
              // padding: 0px;
            }
          }
        }
      }

      .rgt-cell {
        background-color: transparent;
        border: none;

        .rgt-text-truncate {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase !important;

          @include mobile {
            font-size: 12px;
          }
        }
      }

      .rgt-cell-actions {
        > div {
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: center !important;
        }

        > div > svg {
          color: black;
        }
      }
    }

    @include mobile {
      .rgt-container {
        grid-template-columns: 1fr 85px auto !important;
      }

      .rgt-cell-inner {
        margin: 0px 10px;
      }

      .rgt-cell-inner-not-pinned-rught {
        border-top-right-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
      }
    }

    .rgt-footer {
      background-color: transparent;
      border: none;

      .rgt-footer-items-information,
      .rgt-footer-right-container {
        color: white;
      }

      .rgt-footer-page-size {
        align-items: center;
      }
    }
  }

  .icon-client {
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.trash {
      background-color: #ffaaa4;
      color: #e82314;
    }

    &:hover,
    &:focus {
      background-color: #5e5e66;
    }
  }
}

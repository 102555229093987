.budget-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: hidden;
  background-color: #67676a;

  .budget-body {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    border-radius: 16px;
    padding: 10px;

    @include mobile {
      height: calc(100% - 40px);
    }
  }
}

.budget-gridtable {
  // .rgt-wrapper {
  //   overflow-y: scroll;
  //   max-height: 85vh;
  // }

  .rgt-container {
    z-index: 0;
    grid-template-columns: 140px 1fr 1fr 1fr 195px 95px auto !important;
    overflow-y: auto;
    height: calc(100% - 200px); // Defina a altura conforme necessário
  
    /* Estilos da barra de rolagem para navegadores WebKit */
    
    @include mobile {
      // grid-template-columns: 1fr 1fr 1fr 1fr 60px 60px 0px auto !important;
      grid-template-columns: 1fr 1fr 111px 0px !important;
    }
    .rgt-row-odd {
      background-color: #767676 !important;
    }
    .rgt-row-even {
      background-color: #565659 !important;
    }
    .rgt-cell-header-sortable {
      @include mobile {
        background-color: #67676a;
        border-bottom-right-radius: 16px;
      }
      background-color: #67676a;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-status {
      @include mobile {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .rgt-cell-header {
      .rgt-text-truncate {
        @include mobile {
          font-size: 8px;
        }
      }

      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
          @include mobile {
            padding-left: 10px !important;
            padding-right: 0px !important;
          }
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding: 20px !important;
          @include mobile {
            padding-left: 10px !important;
          }
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
          @include mobile {
            padding-left: 10px !important;
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
          }
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
          @include mobile {
            padding-left: 10px !important;
          }
        }
        @include mobile {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
            padding-left: 20px !important;
          }
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(6) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }

      // &:nth-child(7) {
      //   .rgt-cell-header-inner {
      //     border-top-right-radius: 0px !important;
      //     border-bottom-right-radius: 0px !important;
      //     padding-left: 20px !important;
      //   }
      // }
    }
  }
}

.react-datepicker-wrapper {
  display: flex !important;
  width: 30% !important;
  flex-wrap: wrap !important;
  align-content: center !important;
  background-color: #d9dbe9;
  border-radius: 8px;

  > div > input {
    color: black;
    padding-left: 10px;
  }

  > div > input::placeholder {
    color: black !important;
  }

  @include mobile {
    width: 100%;
  }
}

.react-datepicker__close-icon::after {
  background-color: $primary-color !important;
}
@include mobile {
  .example-custom-input {
    color: black !important;
    font-size: 13px;
    padding-left: 10px;
    &::placeholder {
      color: black !important;
      font-size: 13px;
      padding-left: 10px;
    }
  }
}

.custom-header-input {
  height: 31.99px;
  width: 76.65px;
  background-color: #d9dbe9;
  padding: 1px 10px !important;
  border-radius: 8px;
  color: #000000 !important;
  margin-right: 0px !important;
  &::placeholder {
    color: black !important;
    font-size: 13px;
  }
}

.custom-header-icon {
  color: white !important;
}

// .payment-dropdown {
//   padding-top: 5px;
//   padding-bottom: 5px;
//   @include mobile {
//     > div {
//       padding-top: 10px;
//       // padding-left: 10px;
//       // padding-right: 10px;
//     }
//   }

//   > div > button {
//     width: 100%;
//     border-radius: 8px;
//   }

//   > div > div > button {
//     width: 100%;
//   }
// }

.size-dropdown {
  width: 100%;

  > text {
    padding-left: 5px;
  }

  > div > div {
    margin: 0px !important;
  }

  > div > button {
    width: 100%;
    border-radius: 8px;

    &:hover {
      background-color: #67676a;
    }

    @include mobile {
      width: 100%;
      margin: 0px 5px 0px 0px;
    }
  }
}

.dropdown-menu {
  &.show {
    width: 100%;
  }
}

.form-switch {
  margin-bottom: 0px !important;
}

.colorful-status {
  border-radius: 5px;
  width: fit-content !important;
  padding-right: 20px;

  @include mobile {
    > text {
      padding-right: 10px !important;
    }
  }
}

.colorful-status-icon {
  @include mobile {
    padding-top: 3px !important;
    margin-left: 10px;
    height: 22px !important;
  }

  height: 31px;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-top: 3px !important;
  margin-left: 25px;
}

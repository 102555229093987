.div-filters {
    display: flex;
    flex: 1;
    flex-direction: column;
    // margin: 10px;

    div[class*="-multiValue"] {
        font-size: 13px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
        background-color: transparent;
        border: 1px solid #333333;
        border-radius: 4px;
        padding-left: 5px;

        div {
            padding: 0px;
            color: #333333;
            // font-weight: bold;

            &[role=button] {
                padding-inline: 3px;
                background-color: transparent;
                // display: none;
            }
        }
    }

    .input-select {
        width: 100% !important;
        margin-bottom: 10px !important;
        margin-inline: 0px;
    }

    .dashboard-label {
        margin-bottom: 0px;
    }

    .title-text {
        margin-bottom: 20px;
    }

    .spinner-clear {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
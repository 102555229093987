.button {
    border-radius: 4px;
    padding: 12px 10px;
    color: white;
    background-color: $primary-color;
    cursor: pointer;

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        // letter-spacing: 0.75px;
        margin: 0px 10px;
    }

    &.dark {
        background-color: $primary-color-dark;
    }

    &.secondary {
        background-color: $secondary-color;
    }

    &:hover {
        color: white;
        background-color: $primary-color-light;

        &.dark {
            background-color: $primary-color;
        }
    }

    /* Outline, ou seja, apenas borda */
    &.outline {
        border-width: 2px;
        background-color: transparent;

        &.primary {
            border-color: $primary-color;
            color: $primary-color;
        }

        &.dark {
            border-color: $primary-color-dark;
            color: $primary-color-dark;
        }

        &.background {
            border-color: $primary-color;
            color: $primary-color;
            background-color: $primary-color-light;
        }

        &:hover {
            color: white;
            background-color: $primary-color;

            &.dark {
                background-color: $primary-color-dark;
            }
        }
    }
}

.link-button {
    border-radius: 8px;
    padding: 0px;
    color: $primary-color;
    background-color: transparent !important;
    cursor: pointer;

    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        // letter-spacing: 0.75px;
    }

    &.dark {
        color: $primary-color-dark;
    }

    &:hover {
        color: $primary-color-light;

        &.dark {
            color: $primary-color;
        }
    }

    &:focus {
        border: none;
        box-shadow: none;
    }
}

.btn {
    box-shadow: none !important;
}
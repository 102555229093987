.modal-budget-gridtable {
  .rgt-container {
    // background-color: #4b4b4d;
    // grid-template-columns: auto auto auto auto !important;

    // @include mobile {
    //     grid-template-columns: 90px 1fr auto 70px !important;
    // }

    .rgt-cell {
      // color: rgb(255, 255, 255) !important;
      // background-color: #4b4b4d;
      border: none !important;
      // max-height: 20px !important;
      min-height: 40px !important;

      .rgt-text-truncate {
        color: rgb(255, 255, 255) !important;
        margin: 0px 5px;
      }
    }

    .rgt-header-container {
      border: none !important;
    }

    .rgt-cell-header {
      border: none !important;
    }
  }

  .rgt-footer {
    border: none !important;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
}

.mr-1 {
  margin-right: 4px;
}

.lineItens {
  display: flex;
  justify-content: space-between;
}

.budget-service-total {
  flex: 1;
  margin: 0px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  > div > input {
    max-height: 20px !important;
    min-height: 20px !important;
    @include mobile {
      font-size: 10px !important;
      padding: 0px 0px 0px 5px;
    }
  }
}

.budget-service-hour {
  flex: 1;
  margin: 0px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  @include mobile {
    min-width: 40px;
    width: 45px;
  }
  > div > div > div {
    max-height: 20px;
    min-height: 20px;
    padding: 0px;
  }
  > div > div > div > input {
    @include mobile {
      font-size: 10px;
    }
  }
}

.budget-service-unit {
  flex: 1;
  margin: 0px !important;
  min-width: 75px;
  min-height: 20px !important;
  max-height: 20px !important;
  > div > input {
    padding: 0px 0px 0px 5px;
    min-height: 20px !important;
    max-height: 20px !important;
    @include mobile {
      font-size: 10px !important;
    }
  }
}

.budget-piece-quantity {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  > div > div > input {
    max-height: 20px;
  }
}

.budget-piece-unit {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  min-width: 75px;
  > div > input {
    height: 20px;
    padding: 0px 0px 0px 5px;
    @include mobile {
      font-size: 10px !important;
    }
  }
}

.budget-piece-total {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  > div > input {
    height: 20px;
    @include mobile {
      font-size: 10px !important;
    }
    padding: 0px 0px 0px 5px;
  }
}

.fullscreen-gridtable {
  // max-height: 30vh;

  .rgt-container-overlay {
    align-items: self-start !important;
  }
}

.modal-budget {
  > div {
    border: none !important;
  }

  > div > div {
    font-size: 20px;
    border: none !important;
  }
}

.prospera-modal-fullscreen {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  // height: 100vh;
  // padding-bottom: 40px !important;
  background-color: #4b4b4d;
  display: flex;
  flex: 1;
  outline: none !important;
  right: 0;
  top: 0;
  margin: 0px;
  float: right;
}

.budget-total {
  justify-content: space-around;
  background-color: #67676a;
  align-items: center;
  height: 40px;
  display: flex;
  border-radius: 15px;
}

.budget-modal-main-div {
  display: flex;
  gap: 10px;

  > div {
    flex: 1;
    align-self: flex-end;
  }

  @include mobile {
    display: flex;
  }

  .budget-percentage-input {
    margin: 0px !important;
  }

  .budget-label-input {
    margin-bottom: 0px !important;
  }
}

.budget-details-inputs {
  .budget-percentage-input {
    margin: 0px !important;
  }
}

.void-class {
  .budget-percentage-input {
    margin: 0px !important;
  }
}

.budget-details-margin {
  margin: 0px !important;
  margin-bottom: 5px;
}

.mobile-switch-button {
  @include mobile {
    align-self: center !important;
  }
}

.mobile-services-input {
  @include mobile {
    min-width: 70px;
    max-width: 70px;
  }
}

.values-title {
  font-weight: bold;
  font-family: sans-serif;
  text-align: left;
  margin-inline: 5px;
  color: white;
  font-size: 16px;
  margin-bottom: 0px;
  @include mobile {
    font-size: 13px;
  }
}

.values-span {
  color: #62c300;
  font-weight: light;
  font-size: 16px;
  @include mobile {
    font-size: 13px;
  }
}

.service-gridtable {
  .rgt-wrapper {
    @include mobile {
      min-height: 54vh !important;
    }
    min-height: 63.5vh !important;
  }
  .rgt-container {
    // max-height: 63.5vh !important;
    @include mobile {
      // max-height: 54vh !important;
      grid-template-columns: 80% 20% auto !important;
    }

    .rgt-cell-header {
      // max-height: 35px;
      min-height: 35px;
    }

    .rgt-text-truncate {
      @include mobile {
        font-size: 10px;
      }
    }

    .budget-service-input {
      width: 100%;
      color: white;
      border: 0 !important;
      @include mobile {
        > div > div {
          padding: 0px;
        }
      }
    }

    .rgt-cell {
      // max-height: 20px !important;
      min-height: 50px !important;
    }

    .rgt-row-odd {
      background-color: #767676 !important;
    }

    .rgt-row-even {
      background-color: #6f6f72 !important;
    }
    .rgt-cell-header-title {
      background-color: #4b4b4d;
      border-bottom-left-radius: 16px;
    }
    .rgt-cell-title {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    .rgt-cell-header-actions {
      background-color: #4b4b4d;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-actions {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rgt-row-odd .default-input {
      background-color: #4b4b4d !important;
      // border: 0 !important;
    }

    .rgt-row-even .default-input {
      background-color: #4b4b4d !important;
      // border: 0 !important;
    }

    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(6) {
        .rgt-cell-header-inner {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.custom-datepicker .default-input {
  background: #67676a !important;
  color: white;
  border: 0 !important;
}

.custom-datepicker .form-control {
  border-color: 1px solid black !important;
}

.default-input .ant-picker-input > input:hover,
.default-input .ant-picker-input > input:focus {
  border-color: black !important;
}

.custom-datepicker .default-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 13px !important;
  font-weight: normal;
  height: 40px;
  display: flex;
  color: white;
  text-transform: uppercase;
}

.custom-datepicker .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-datepicker input {
  box-shadow: none !important;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  color: white;
  justify-content: center;
}

.custom-datepicker input {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-overflow: ellipsis;
}

.custom-datepicker input,
.custom-datepicker button,
.custom-datepicker select,
.custom-datepicker optgroup,
.custom-datepicker textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.custom-datepicker *,
.custom-datepicker *::before,
.custom-datepicker *::after {
  box-sizing: border-box;
}

.day-input {
  width: 100%;
}

.void-class {
  .css-1jqq78o-placeholder {
    text-transform: uppercase;
    color: white !important;
  }
}

.piece-gridtable {
  .rgt-wrapper {
    @include mobile {
      min-height: 59vh !important;
    }
    min-height: 72.5vh !important;
  }
  .rgt-container {
    max-height: 72.5vh !important;
    @include mobile {
      max-height: 59vh !important;
      grid-template-columns: 80% 20% auto !important;
    }

    .rgt-cell {
      // color: rgb(255, 255, 255) !important;
      // background-color: #4b4b4d;
      border: none !important;
      // max-height: 20px !important;
      min-height: 50px !important;
    }

    .rgt-cell-header {
      max-height: 35px;
      min-height: 35px;
    }

    .rgt-text-truncate {
      @include mobile {
        font-size: 10px;
      }
    }

    .rgt-row-odd {
      background-color: #767676 !important;
    }

    .rgt-row-even {
      background-color: #6f6f72 !important;
    }

    .rgt-cell-header-name {
      background-color: #4b4b4d;
      border-bottom-left-radius: 16px;
    }
    .rgt-cell-name {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .rgt-cell-header-actions {
      background-color: #4b4b4d;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-actions {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rgt-row-odd .default-input {
      background-color: #4b4b4d !important;
      padding: 0px 0px 0px 5px;
      @include mobile {
        font-size: 10px !important;
      }
      // border: 0 !important;
    }

    .rgt-row-even .default-input {
      background-color: #4b4b4d !important;
      padding: 0px 0px 0px 5px;
      @include mobile {
        font-size: 10px !important;
      }
      // border: 0 !important;
    }

    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 10px !important;
          @include mobile {
            border-top-right-radius: 16px !important;
            border-bottom-right-radius: 16px !important;
          }
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.service-additional-off {
  margin-top: 10px;
  margin-bottom: 10px;
}

.service-additional-on {
  margin-top: 0px;
  margin-bottom: 0px;
}

.add-button-budget {
  display: inline-flex;
  height: 40px;
  align-items: center;
  align-self: flex-end;
  margin-right: 5px;
  // margin-top: 45px;

  @include mobile {
    // margin-top: 37px;
    // margin-bottom: 0px;
  }

  &.external {
    margin-right: 10px;
    margin-top: 0px !important;
  }
}

.modal-content-wrapper2 {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.main-content-modal1 {
  margin-top: 20px;
}

.main-content-modal2 {
  width: 30%;
  max-width: 100%;
  margin: 5px !important;
  flex: 1px 1;
}

.secondary-content-modal {
  width: 100%;
  margin: 5px !important;
  flex: 1px 1;
}

.modal-content-wrapper2 {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

.main-content-modal1 {
  margin-top: 10px;
}

.main-content-modal2 {
  width: 100%;
  margin: 5px !important;
  flex: 1px 1;
}

.secondary-content-modal {
  width: 100%;
  margin: 5px !important;
  flex: 1px 1;
}

.values-box {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.dont-show-but-print {
  display: none;
  width: 800px !important;

  @media print {
    display: contents;
  }
}

.print-scss {
  display: none;
  background-color: white;
  height: 100vh;
  position: absolute;
  z-index: -10;
  width: 800px;
  @media print {
    display: table;
    width: 800px !important;
  }
}

.modal-print {
  // > span {
  //   width: 115px;
  // }

  @include mobile {
    padding: 0px !important;
  }
}

.swal2-styled.swal2-confirm {
  background-color: #59a70b !important;
}

.to-share-button {
  @include mobile {
    margin-left: 10px !important;
  }
}

.rgt-cell {
  // max-height: 10px !important;
}

.mobile-switch-div {
  display: flex;
}

.fullscreen-gridtable {
  .rgt-wrapper {
    min-height: 69vh !important;
  }
  .rgt-container {
    max-height: 69vh !important;
    @include mobile {
      grid-template-columns: 1fr 1fr 1fr 150px 1fr auto !important;
    }

    .rgt-row-odd {
      background-color: #767676 !important;
    }

    .rgt-row-even {
      background-color: #6f6f72 !important;
    }

    .rgt-cell-title {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .rgt-cell-header-name {
      background-color: #4b4b4d;
      border-bottom-left-radius: 16px;
    }
    .rgt-cell-name {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .rgt-cell-header-actions {
      background-color: #4b4b4d;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-actions {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rgt-row-odd .default-input {
      background-color: #767676 !important;
      // border: 0 !important;
    }

    .rgt-row-even .default-input {
      background-color: #6f6f72 !important;
      // border: 0 !important;
    }

    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(3) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 10px !important;
        }
      }

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.deletable-creatable {
  &:hover {
    background-color: #767676;
  }
  &:focus {
    background-color: #767676;
  }
}

.loading {
  overflow-y: hidden !important;
}

.container-course {
    display: flex;
    overflow-y: auto;
}

.menu-sidebar {
    width: 30%;
    background-color: #333;
    color: #fff;
    border-right: 2px solid #444;
    overflow-y: auto;
    max-height: 100vh;
}

.menu-item {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
}

.menu-title {
    margin-left: 10px;
}

.item-text {
    color: white
}

.menu-item:hover {
    background-color: #444;
}

.menu-item-active {
    background-color: #555;
}

.video-container {
    flex: 1;
    padding: 20px;
    background-color: #222;
    color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    width: 70%;
}

.video-frame {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
}

.video-frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.menu-header {
    margin-bottom: 20px;
    margin-left: 16px;
    margin-right: 20px;
    display: none;
}

.video-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.video-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
}

@media (max-width: 768px) {
    .menu-header {
        display: block;
    }

    .video-select {
        display: block;
    }

    .video-container h2 {
        font-size: 18px;
    }

    .menu-sidebar {
        display: none;
    }

    .container-course {
        display: block;
    }

    .video-container {
        width: 100%;
    }

    .item-text {
        color: white;
        font-size: 16px;
    }
}
.grid-tables-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.externalBudget-service-total {
  flex: 1;
  margin: 0px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

.externalBudget-service-hour {
  flex: 1;
  margin: 0px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

.externalBudget-service-unit {
  flex: 1;
  margin: 0px !important;
  min-width: 75px;
  min-height: 20px !important;
  max-height: 20px !important;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

.externalBudget-piece-quantity {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

.externalBudget-piece-unit {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  min-width: 75px;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

.externalBudget-piece-total {
  flex: 1;
  margin: 0px !important;
  width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  background-color: #4b4b4d !important;
  border-radius: 5px !important;
  padding: 4px;
}

// @media screen and (min-width: 768px) {
//   .bodyshop-body.modal-budget.modal-budget-gridtable {
//     width: 51%;
//   }
// }

// @media screen and (max-width: 767px) {
//   .bodyshop-body.modal-budget.modal-budget-gridtable {
//     width: 51%;
//   }
// }
.modal-budget-gridtable {
  .rgt-container {
    background-color: #4b4b4d;
    // grid-template-columns: auto auto auto auto !important;

    // @include mobile {
    //     grid-template-columns: 90px 1fr auto 70px !important;
    // }

    .rgt-cell {
      color: rgb(255, 255, 255) !important;
      background-color: #4b4b4d;
      border: none !important;

      .rgt-text-truncate {
        color: rgb(255, 255, 255) !important;
      }
    }

    .rgt-header-container {
      border: none !important;
    }

    .rgt-cell-header {
      border: none !important;
    }
  }

  .rgt-footer {
    border: none !important;
  }
}

.modal-budget {
  > div {
    border: none !important;
  }

  > div > div {
    border: none !important;
  }
}

.prospera-modal-fullscreen {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  // height: 100vh;
  // padding-bottom: 40px !important;
  background-color: #4b4b4d;
  display: flex;
  flex: 1;
  outline: none !important;
  right: 0;
  top: 0;
  margin: 0px;
  float: right;
}

.budget-total {
  justify-content: space-around;
  background-color: #67676a;
  align-items: center;
  height: 40px;
  display: flex;
  border-radius: 15px;
}

.budget-modal-main-div {
  display: flex;
  gap: 10px;

  > div {
    flex: 1;
  }

  @include mobile {
    display: flex;
  }
}

.gridtable-for-service-budget {
  // min-height: 160px;
  // text-align: center;

  .rgt-wrapper {
    min-height: auto;
  }

  .rgt-wrapper ::-webkit-scrollbar {
    background-color: #70e000 !important;
    color: black !important;
  }

  .rgt-wrapper ::-webkit-scrollbar-track {
    background-color: #70e000 !important;
    color: black !important;
  }

  .rgt-wrapper ::-webkit-scrollbar-thumb {
    background-color: #70e000 !important;
    color: black !important;
    border: black !important;
  }
  .rgt-container {
    .rgt-row-odd {
      background-color: #767676 !important;
      border-radius: 10px;
    }
    .rgt-row-even {
      background-color: #6f6f72 !important;
      border-radius: 10px;
    }
    .rgt-cell {
      min-height: 50px !important;
      > div {
        font-size: 13px !important;
      }
    }
    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.gridtable-for-piece-budget {
  min-height: 160px;
  // text-align: center;

  .rgt-wrapper {
    min-height: auto;
  }

  .rgt-wrapper ::-webkit-scrollbar {
    background-color: #70e000 !important;
    color: #70e000 !important;
  }

  .rgt-wrapper ::-webkit-scrollbar-track {
    background-color: #70e000 !important;
    color: #70e000 !important;
  }

  .rgt-wrapper ::-webkit-scrollbar-thumb {
    background-color: black !important;
    color: black !important;
    border: transparent !important;
  }
  .rgt-container {
    .rgt-row-odd {
      background-color: #767676 !important;
      border-radius: 10px;
    }
    .rgt-row-even {
      background-color: #6f6f72 !important;
      border-radius: 10px;
    }
    .rgt-cell {
      min-height: 50px !important;
      > div {
        font-size: 13px !important;
      }
    }
    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

.validity-input {
  width: 100%;
}

.day-input {
  width: 100%;
}

.void-class {
  .css-1jqq78o-placeholder {
    text-transform: uppercase;
    color: white !important;
  }
}

.prospera-modal-external {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  // height: 100vh;
  // padding-bottom: 40px !important;
  background-color: #4b4b4d;
  display: flex;
  flex: 1;
  outline: none !important;
  right: 0;
  top: 0;
  margin: 0px;
  float: right;
}

.imageExternal {
  position: relative !important;
  // height: 100%;
  background-attachment: fixed;
  pointer-events: none;
}

.backWorkerExternal {
  width: 100vw;
  height: 100vh;
  background-image: url('https://i.imgur.com/2n6UoTB.png');
  background-size: cover;
  background-attachment: fixed;
  filter: brightness(50%);
  z-index: 0;
  pointer-events: none;

  @include mobile {
  }
}

.external-bcontainer {
  display: flex !important;
  height: 100vh !important;
  justify-content: center !important;
  align-items: center !important;

  @include mobile {
    overflow: hidden;
  }

  &.menu {
    height: 100% !important;
  }
}

.eb-container {
  overflow-y: scroll;
  background-color: #4b4b4d !important;
  position: absolute !important;
  z-index: 214748290 !important;
  padding: 30px !important;
  display: inline !important;
  border-radius: 20px !important;
  margin-inline: auto !important;
  height: 96vh;
  max-height: 300% !important;
  max-width: 55% !important;
  inset: 16px !important;
  cursor: default;

  @include mobile {
    inset: 0px !important;
    height: 100vh;
    border-radius: 0px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 5px;
  }

  // pointer-events: none !important;

  @include mobile {
    display: flex;
    overflow-y: auto;
    padding: 10px !important;
    max-height: 100% !important;
    max-width: 100% !important;
  }
}

.analyse-btn {
  height: 55px !important;
  background-color: #6eb6ff;
  width: 100%;
  margin: 0px !important;
  @include mobile {
    span {
      font-size: 11px;
    }
    height: 40px !important;
  }
}

.doubt-btn {
  height: 55px !important;
  background-color: #eead2d;
  width: 100%;
  margin: 0px !important;
  @include mobile {
    span {
      font-size: 11px;
      line-height: 15px;
    }
    height: 40px !important;
  }
}

.pdf-btn {
  height: 55px !important;
  background-color: #b5b5b5;
  width: 100%;
  margin: 0px !important;
  @include mobile {
    span {
      font-size: 11px;
    }
    height: 40px !important;
  }
}

.approve-btn {
  height: 55px !important;
  width: 100%;
  margin: 0px !important;
  margin-bottom: 20px !important;
  @include mobile {
    margin-bottom: 0px !important;
    span {
      font-size: 11px;
    }
    height: 40px !important;
  }
}

.external-budget-inputs {
  align-self: flex-end;
  .mb-3 {
    margin: 0px !important;
  }
}

.share-btn {
  height: 55px !important;
  width: 100%;
  margin: 0px !important;
  cursor: not-allowed !important;
  @include mobile {
    height: 35px !important;
  }
  &:hover {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
}

@media print {
  .mb-3 {
    // margin: 0px !important;
  }
  .eb-container-print {
    // margin: 0px !important;
    // padding: 0px !important;
  }
  .btn-no-print {
    display: none;
  }
}

.external-observation-input {
  margin: 0px !important;
  .text-area-min {
    min-height: 120px !important;
  }
}

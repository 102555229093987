.pie-chart-main-div {
    width: 100%;
    height:300px;
// }
// .pie-chart-primary {
//     // width: 125px !important;
//     // height: 125px !important;
//     position: relative;
//     text-align: center;
//     display: flex;
//     flex: 1;

//     .overlay {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         position: absolute;
//         justify-content: center;
//         font-size: 96px;
//         color: "#FFFFFF";
//         text-align: center;
//         pointer-events: none;
//         top: 8px;
//         left: 45px;
//         bottom: 0px;
    
//     }
}

.recovery-container {
  @include mobile {
    .form {
      top: 75px !important;
      width: 25%;
    }
  }
}

.image {
  position: absolute;
}

.backWorker {
  width: 100vw;
  height: 100vh;
  filter: brightness(50%);

  @include mobile {
  }
}

.login-container,
.newPassword-container,
.recovery-container {
  display: flex;
  // background: linear-gradient(112.07deg, #27bc67 2.13%, #007332 99.26%);
  height: 100vh;
  justify-content: center;
  align-items: center;

  @include mobile {
    overflow: hidden;
  }

  &.menu {
    height: 100% !important;
  }

  .left {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px;
    color: white;

    @include mobile {
      display: none;
    }

    .title {
      margin-top: 40px;
      color: white;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      letter-spacing: 1px;
    }

    .line {
      width: 25%;
      height: 5px;
      background-color: white;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      letter-spacing: 2px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 34px;
      // letter-spacing: 0.75px;
    }

    .logo {
      width: 107px;
    }

    .backWorker {
      width: 100vw;
      height: 100vh;
      background-color: #27bc67;
    }
  }

  .loginInfo {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .loginLabel {
    color: white;
  }

  .forgotPassword {
    color: #70e000;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 2px;
    cursor: pointer;

    &:hover {
      opacity: 0.3;
    }
  }

  .form {
    background-color: #4b4b4d !important;
    // flex: 1;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    // padding: 50px;
    // justify-content: space-evenly;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    // justify-content: center;
    overflow: auto;
    height: 85%;
    width: 60%;
    z-index: 10;
    min-height: 578px;
    // position: absolute;

    &.outside {
      height: auto;

      .inputs .generic-input {
        margin-top: 10px !important;
      }
    }

    // @include tablet {
    //     position: absolute;
    //     top: 10px;
    //     left: 10px;
    //     right: 10px;
    //     bottom: 10px;
    //     border-radius: 30px;
    // }

    @include mobile {
      position: fixed !important;
      border-radius: 30px;
      padding: 10px;
      align-items: center;
      text-align: center;
      height: 90%;
      width: 90%;
    }

    @include mini-mobile {
      position: fixed !important;
      border-radius: 30px;
      padding: 10px;
      align-items: center;
      text-align: center;
      height: 90%;
      width: 90%;
    }

    .logo {
      width: 250px;
      margin-bottom: 15px;
      // margin-bottom: -30%;

      @include mini-mobile {
        width: 120px;
        // margin-bottom: -30%;
      }

      @include mobile {
        width: 180px;
        // margin-bottom: -30%;
      }
    }

    .title {
      // margin-top: 30px;
      color: white;
      font-weight: 700;
      font-size: 30px;
      letter-spacing: 1px;

      @include mobile {
        margin-top: 30px;
        color: white;
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 1px;
      }

      @include mini-mobile {
        margin-top: 30px;
        color: white;
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 1px;
      }
    }

    .line {
      width: 15%;
      height: 5px;
      background-color: $primary-color;
      border-radius: 100px;
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .description {
      color: #f7f7fc !important;
      background-color: transparent !important;
      // letter-spacing: 0, 75px;
      font-weight: 400;
      font-size: 18px;
    }

    .login-input {
      width: 100% !important;
      text-transform: none;
    }

    .inputs {
      text-align: left;
      width: 75%;
      margin-top: 15px;

      .div-icon-eye {
        background: #67676a !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        display: flex;
        align-items: center;
        padding: 10px;
        color: white;
        cursor: pointer;
      }

      .generic-input {
        margin-top: 30px !important;
        width: 100% !important;

        &.error {
          border-color: "red";
          background-color: #27bc67;
        }

        .label {
          margin-bottom: 10px;
        }
      }
    }

    .navButonLog {
      display: flex;
      flex-direction: column;

      .loginBtn {
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }

    .button {
      padding-inline: 40px;
    }

    .link-button {
      text-decoration: none;
    }
  }

  .backWorker {
    width: 100vw;
    height: 100vh;
  }

  .backBtn {
    display: flex;
    align-items: center;
  }

  .backIcon {
    color: #70e000;
    margin-right: 10px;
    font-size: 20px;
  }

  .form-control {
    color: white;
    text-transform: none;
  }
}

.gridtable-for-hour-value-chart {
  width: 100% !important;

  .rgt-container {
    .rgt-cell {
      min-height: 35px !important;
    }
    grid-template-columns: 90px 1fr auto 90px !important;
    @include mobile {
      grid-template-columns: 90px 1fr auto 90px !important;
    }
    .rgt-cell-header-actions {
      background-color: #67676a;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-actions {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .rgt-cell-header-additionalColumn {
      background-color: #67676a;
      border-bottom-left-radius: 16px;
    }
    .rgt-cell-additionalColumn {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }

    .rgt-text-truncate {
      margin: 0px;
    }
  }
}

$sidebar-bg-color: $primary-color !default;
$sidebar-color: #edf2f7 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #edf2f7 !default;
$submenu-bg-color: $primary-color !default;
$submenu-bg-color-collapsed: $primary-color !default;
$icon-bg-color: $primary-color !default;
$icon-size: 25px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar {
  // z-index: 0 !important;
  // position: absolute;

  &:not(.collapsed) {
    .pro-sidebar-footer {
      height: 64px !important;
    }
  }

  .pro-sidebar-footer {
    @include mobile {
      height: auto;
      border-top: none !important;

      .pro-menu-item {
        &.mobile {
          padding: 13px 10px 15px 10px !important;
        }
      }
    }

    .pro-icon {
      border: 0px solid !important;
    }

    li {
      list-style: none;
    }
  }

  @include mobile {
    position: fixed !important;

    .pro-menu-item {
      > .pro-inner-item {
        background: transparent !important;

        .pro-icon-wrapper {
          .pro-icon {
            color: white;
          }
        }
      }
    }
  }

  .expand-icon {
    color: $text-placeholder-color;
    font-size: 25px;
    padding-left: 25px;
    outline: none !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 40px !important;

    @include mobile {
      height: 65px;
      padding-right: 25px;
    }

    span {
      padding-left: 20px;
      font-weight: 600;
      color: $text-label-color;
    }
  }

  &.collapsed {
    .expand-icon {
      display: block;
      // margin-top: 10px;

      svg {
        // top: -3px;
        position: relative;
      }
    }

    @include mobile {
      height: 60px !important;

      .pro-icon {
        top: -4px;
      }
    }

    .pro-sidebar-footer {
      width: 70px;

      .pro-icon {
        top: -4px;
        color: rgba(244, 15, 7, 0.503);
      }

      .pro-item-content {
        display: none;

        // color: red !important;
      }
    }

    .pro-inner-list-item {
      display: none;
    }
  }

  .pro-sidebar-footer {
    .pro-menu-item {
      padding: 9px 35px 8px 10px;

      .pro-item-content {
        position: relative;
        top: 2px;
        padding-left: 5px;
        // color: red !important;
      }

      .pro-icon {
        svg {
          color: white;
        }
      }
    }
  }

  .pro-sidebar-content {
    overflow-y: auto;

    .pro-inner-list-item {
      padding-left: 42px !important;
      background-color: transparent !important;
    }
  }

  .pro-icon {
    border: #70e000 2px solid;
    border-radius: 30px;
    padding: 6px;

    svg {
      height: $icon-size;
      width: $icon-size;
      color: #70e000;
    }
  }

  .pro-sidebar-inner {
    background-color: #25262a !important;
    // width: 100% !important;
  }

  .pro-sidebar-header {
    height: 65px;
    border-bottom: 0px !important;
  }

  .pro-menu-item {
    padding: 15px 35px 8px 10px;

    .pro-icon-wrapper {
      background-color: transparent !important;
    }
  }

  .pro-inner-list-item {
    .pro-menu-item {
      padding-right: 0px !important;

      .pro-inner-item {
        padding: 0px !important;
        padding-left: 13px !important;
      }
    }
  }

  .pro-menu-item {
    &.active {
      .pro-inner-item {
        background: transparent !important;
        // display: flex !important;
        border-radius: 8px;
        padding: 8px 20px 8px 20px;
        margin-left: 5px;

        .pro-icon {
          background: #70e000;
          color: white !important;

          svg {
            color: white;
          }
        }

        .pro-item-content {
          color: #70e000 !important;
        }

        svg,
        .pro-item-content {
          color: #70e000;
        }
      }
    }

    .pro-inner-item {
      background: transparent !important;
      display: flex !important;
      padding: 8px 20px 8px 20px;
      margin-left: 5px;

      .pro-item-content {
        color: white !important;
        display: flex;
        font-weight: 600;

        &.exit {
          margin-inline: -15px;
        }
      }
    }

    .pro-icon {
      left: -12px;
      top: 0px;
      position: relative;
      color: white;
    }
  }

  .admin-nav-avatar {
    display: none;
  }

  .sidebar-logo {
    width: 80%;
    padding: 20px;

    &.mini {
      width: 100%;
    }
  }

  .icon-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 20px;

    svg {
      font-size: 20px;
    }

    &.collapsed {
      margin-left: 0px;
      justify-content: center;
    }
  }

  .text-collapse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-style: normal;
    margin-left: 10px;
    font-size: 13px;
  }

  @include mobile {
    position: fixed;
    top: 0;
    min-width: 100% !important;
    width: 100% !important;
    z-index: 99999999 !important;

    .admin-nav-avatar {
      display: flex;
      padding: 7px;

      .title-container {
        display: none;
      }

      .dropdown-menu {
        background-color: transparent;
      }
    }

    .icon-collapse {
      &.collapsed {
        margin: 0px 5px 6px 20px !important;
      }
    }

    .pro-sidebar-layout {
      background: red;
      overflow: hidden;
    }

    &.collapsed {
      min-width: 100% !important;
      width: 100% !important;
      // height: 15%;
      // height: 70px !important;
      display: flex;
      width: 100% !important;
      flex: 1;
      flex-direction: column;
      height: 10%;

      .pro-sidebar-layout {
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-end;
        height: 100%;
        overflow: hidden !important;
        background: #67676a;

        .pro-sidebar-content {
          display: flex !important;
          flex-direction: row !important;
          width: 100%;

          .pro-menu {
            width: 100%;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            @include mobile {
              padding: 0px !important;
            }

            &::-webkit-scrollbar {
              display: none;
            }

            margin-right: 10px;

            ul {
              display: flex;
              flex-direction: row;
              margin-left: 15px;
              // flex-wrap: wrap;
            }
          }
          // .pro-menu-item:nth-child(6) {
          //     display: none;
          // }
          .pro-menu-item {
            padding: 0px;

            &.active {
              .pro-inner-item {
                // margin-left: 0px;
                background: transparent !important;
              }

              .pro-icon {
                // left: -12px;
                position: relative;
              }
            }

            .pro-inner-item {
              // padding: 8px 25px 12px 20px; //8px 20px 8px 20px;
              padding-right: 25px;
              margin-left: 5px;
              // display: flex !important;
              background: transparent !important;
            }

            .pro-icon-wrapper {
              width: $icon-size;
              min-width: $icon-size;
              height: $icon-size;
              line-height: $icon-size;
            }

            .pro-icon {
              left: -22px;
              position: relative;
              width: 28px !important;
              height: 28px !important;
              color: white !important;

              .small {
                height: 27px;
                width: 27px;

                @include mobile {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }

          .pro-menu-item {
            margin-right: 15px;
            max-width: 40px !important;
            width: 40px !important;
          }

          // .pro-item-content {
          //     // display: none;
          //     color: red;
          // }

          .logo {
            display: none !important;
          }
        }
      }
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
}

.pro-sub-menu {
  .pro-arrow {
    padding: 4px !important;
  }

  &.open {
    .pro-arrow {
      margin-bottom: 7px;
    }
  }
}

.react-tooltip-lite-arrow {
  color: #25262a;
  z-index: 9999 !important;
  margin-top: 3px;
}

.react-tooltip-lite {
  background-color: #25262a;
  width: auto !important;
  padding-right: 5px !important;
  // margin-top: 8px;
  font-size: 14px;
  // font-family: "Inter";
  color: white;
  border-radius: 4px;
  font-weight: 700;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  width: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow: hidden !important;
  background: #67676a !important;
}

.icon-container {
  width: 35px;
  height: 35px;
  display: flex;
  border: 2px #70e000 solid;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-left: 6px;
  // margin-top: 23px;
}

.small {
  height: 25px !important;
  width: 19px !important;
  color: #70e000;
}

.modules {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-top: -19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 17px;
  cursor: pointer;
}

.text-version {
  font-size: 14px;
  text-align: center;
  color: white;
}

.card-container {
    background-color: #4b4b4d;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 5px;
    width: 40%;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    min-width: 425px;
}

.card-contain {
    width: 90%;
   min-width: 375px;
    margin: 10px;
    text-align: center;
    background-color: transparent;
    border-radius: 5px;
}

.title-div {
    background-color: #b70d00;
    border-radius: 5px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.card-title {
    margin-bottom: 0;
    color: white;
}

.card-cell {
    background-color: #67676a;
    display: flex;
    flex-direction: row;
    min-width: 375px;
    border-radius: 15px;
    padding-right: 10px;
    max-height: 170px;
    justify-content: space-between;
    align-items: center;
}
.card-cell-image {
    display: flex;
    align-items: center;
}

.card-cell-info {
    margin-left: 10px;
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.os-cod {
    font-size: 13px;
    font-weight: 500;
    color: white;
}

.info-text {
    font-size: 14px;
    font-weight: 400;
    color: #4e4b66;
}

.service-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.service-info-type {
    color: white;
    background-color: #b70d00;
    padding: 5px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

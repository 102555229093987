.card-header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-direction: row;
  align-items: center;
  border-bottom: 0px;
  min-height: 70px;

  .icon-header {
    cursor: pointer;
    color: $text-label-color !important;
  }

  .flex-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    @include mobile {
      gap: 10px;
    }
  }

  .title {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
    padding: 0rem 1rem;

    @include mobile {
      padding: 0px;
    }
    // display: flex;
  }

  .perfil {
    background-color: transparent;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 10px;
    // max-width: 17vh;
    overflow: hidden;
    text-overflow: clip;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    &.main {
      background-color: #67676a !important;
      width: 150px;
    }

    @include mobile {
      &.main {
        width: auto;

        .perfil-text {
          // display: none;
          display: inline-block;
          width: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg {
          margin-left: 5px;
        }
      }
    }
  }

  .nav-link {
    padding: 0px;
  }

  .perfil-text {
    font-family: 'poppins';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
    // margin-inline: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
  }

  .perfil-logo {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }

  .drop-logout {
    padding: 1px 6px;
    border-top: 1px #a0a3bd solid;
  }

  .perfil-logout {
    background-color: transparent;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 10px;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    .main {
      background-color: #67676a;
    }
  }

  .perfil-icon {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
  }

  .dropdown-menu-logout {
    position: absolute !important;
    top: 10px !important;
    right: 0px !important;
    z-index: 999999;

    .dropdown-item {
      &:hover {
        background-color: transparent;
        opacity: 0.8;
      }

      &:active {
        background-color: $primary-color-dark !important;
      }
    }
  }

  .dropdown-menu-header {
    background-color: #67676a;
    // width: 110%;
    border-radius: 8px;
  }

  .drop-item {
    padding: 1px 6px;
  }

  .drop-item-container {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 400px) {
    .dropdown-menu-logout {
      top: 40px !important;
      right: 0px !important;
      left: initial !important;
    }
  }
}

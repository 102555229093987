.rgt-cell-header {
  border: none;

  .rgt-cell-header-inner {
    background-color: #5e5e66;
    border: none;

    .rgt-text-truncate {
      margin: 0px !important;
      color: $line-color;
      padding: 0px 0px;
      font-weight: 500;
      text-transform: uppercase !important;
    }
  }

  &:first-child {
    .rgt-cell-header-inner {
      border-top-left-radius: 16px !important;
      border-bottom-left-radius: 16px !important;
    }
  }

  &:nth-child(2) {
    .rgt-cell-header-inner {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      padding: 0px;
    }
  }

  &:nth-child(5) {
    .rgt-cell-header-inner {
      border-top-right-radius: 16px !important;
      border-bottom-right-radius: 16px !important;
      padding: 0px 9px;
    }
  }
}

.rgt-cell {
  background-color: transparent;
  border: none;

  > div {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase !important;
    // padding-left: 0px;

    .field-class {
      padding-left: 0px !important;
    }

    @include mobile {
      font-size: 10px !important;
    }
  }

  // >div>svg {

  // }
}

.field-actions {
  padding-left: 0px !important;

  > svg {
    color: black;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.icon-cell {
  background-color: #67676a !important;
  border: solid;
  border-color: #82ff05;
  margin-left: 15px !important;
}

.gridtable-for-service-type {
  width: 100% !important;

  .rgt-container {
    .rgt-cell {
      min-height: 35px !important;
    }
    grid-template-columns: 1fr auto 90px !important;
    .rgt-cell-header-title {
      background-color: #67676a;
      border-bottom-left-radius: 16px;
    }
    .rgt-cell-title {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    .rgt-cell-header-actions {
      background-color: #67676a;
      border-bottom-right-radius: 16px;
    }
    .rgt-cell-actions {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    .rgt-cell-header {
      &:nth-child(1) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-left: 20px !important;
        }
      }

      &:nth-child(2) {
        .rgt-cell-header-inner {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }

      &:nth-child(4) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }

    .rgt-text-truncate {
      padding-left: 20px;
      margin: 0px;
    }

    @include mobile {
      grid-template-columns: 1fr auto 90px !important;
    }
  }
}

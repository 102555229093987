.company-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: hidden;

  .company-info {
    display: flex;
    padding: 20px 25px;
    width: 100%;
    justify-content: end;
  }

  .company-body {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    border-radius: 16px;
    padding: 10px;

    @include mobile {
      height: calc(100% - 40px);
    }
  }
}

.user-gridtable {
  @include mobile {
    .rgt-container {
      grid-template-columns: 50px 1fr 70px auto !important;
    }
  }
}

.cellphone-company-input {
  > div > textarea {
    height: 30vh !important;
  }
}

.save-pass-button {
  cursor: pointer !important;
  border-radius: 4px;
  margin-top: 30px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  &.cancel {
    background-color: transparent;
  }

  &.margin-0 {
    margin-left: 0px;
  }

  // &.share {
  //   align-self: flex-end;
  // }

  @include mobile {
    // width: 100%;

    span {
      font-size: 13px;
    }

    &.margin {
      margin-left: 0px;
    }
  }
}

// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal-content {
//   background-color: white;
//   padding: 20px;
//   border-radius: 5px;
// }

// .modal-close {
//   position: relative;
//   top: 10px;
//   left: 90%;
//   /* Estile o botão de fechar como desejar */
// }

.clickable-name {
  color: white;
  padding-left: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 12px;
  &.plate {
    padding-left: 0px;
  }
  @include mobile {
    font-size: 8px;
    padding-left: 10px;
  }
}

.mobile-clickable-name {
  border-radius: 8px;
  width: fit-content !important;
  padding-right: 20px;
  @include mobile {
    font-size: 8px !important;
    padding-right: 10px !important;
    padding-left: 10px;
    > span {
      display: inline-block;
      width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
    }
  }
}

.color-name {
  width: 195px !important;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  @include mobile {
    width: 111px !important;
  }
}

.new-table-button {
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px;

  @include mobile {
    span {
      display: none;
    }
  }
}

.field-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-table {
  .rgt-search-highlight {
    color: red;
    background-color: red;
  }

  .rgt-wrapper {
    border: none;

    .rgt-header-container {
      background-color: transparent;
      border: none;

      .rgt-search-label,
      .rgt-search-icon,
      .rgt-columns-manager-wrapper {
        color: white;
      }

      .rgt-columns-manager-popover-title {
        color: #67676a;
      }

      .rgt-search-input {
        background-color: $line-color;
        padding: 1px 10px;
        border-radius: 8px;
        color: #5e5e66;
        margin-right: 0px;
      }
    }

    .rgt-container {
      background-color: transparent;
      overflow-y: auto;
      -ms-overflow-style: none;
      //scrollbar-width: none;

      @media screen and (max-width: 600px) {
        grid-template-columns: 50px 1fr 105px auto;
      }
      .rgt-row-odd {
        background-color: #767676 !important;
      }
      .rgt-row-even {
        background-color: #565659 !important;
      }
      .rgt-cell-header-logo {
        background-color: #67676a;
        border-bottom-left-radius: 16px;
      }
      .rgt-cell-logo {
        min-height: 35px !important;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .rgt-cell-name {
        min-height: 35px !important;
      }
      .rgt-cell-header-actions {
        border-bottom-right-radius: 16px;
      }
      .rgt-cell-actions {
        min-height: 35px !important;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
      .rgt-cell-budget_actions {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
      .rgt-cell-header-id {
        background-color: #67676a;
        border-bottom-left-radius: 16px;
      }
      .rgt-cell-id {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .rgt-cell-header-status_client {
        background-color: #67676a;
        border-bottom-right-radius: 16px;
      }
      .field-name {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .rgt-cell-header-type {
        .rgt-cell-header-inner {
          padding: 0px !important;
          justify-content: center;
        }
      }
      .rgt-cell-type {
        justify-content: center;
      }
      .rgt-cell-status_client {
        @include mobile {
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #5e5e66;
        // display: none;
      }

      &::-webkit-scrollbar-thumb {
        // background-color: #5E5E66;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: #70e000;
        border-radius: 15px;
      }

      .rgt-cell-header {
        border: none;

        .rgt-cell-header-inner {
          background-color: #5e5e66;
          border: none;
          // padding: 0px !important;
          .rgt-text-truncate {
            color: $line-color;
            padding: 0px 0px;
            font-weight: 500;
            text-transform: uppercase !important;
          }
        }

        &:first-child {
          .rgt-cell-header-inner {
            border-top-left-radius: 16px !important;
            border-bottom-left-radius: 16px !important;
          }
        }

        &:nth-child(3) {
          // width: 100px !important;
          .rgt-cell-header-inner {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0px 9px;
          }
        }
        &:nth-child(4) {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0px !important;
          }
        }
      }

      .rgt-cell {
        background-color: transparent;
        border: none;
        min-height: 35px;
        .rgt-text-truncate {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase !important;
        }
      }
    }

    .rgt-footer {
      background-color: transparent;
      border: none;

      .rgt-footer-items-information,
      .rgt-footer-right-container {
        color: white;
      }

      .rgt-footer-page-size {
        align-items: center;
      }
    }
  }

  .icon-action {
    color: black !important;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.trash {
      background-color: #ffffff;
      color: #e82314 !important;

      &:hover &:focus {
        // background-color: $error-color-dark;
      }
    }

    &.send {
      height: 22px;
    }

    &:hover,
    &:focus {
      // background-color: #5E5E66;
      // opacity: 0.5;
    }
  }

  .rgt-footer-pagination-button {
    box-shadow: none;
    background-color: $primary-color-dark !important;
  }

  .rgt-disabled-button {
    background-color: $secondary-color-dark !important;
  }

  .rgt-search-highlight {
    background-color: rgba(219, 255, 235, 0.3);
  }

  .rgt-container-overlay {
    display: flex;
    text-align: center;
  }

  .image-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .image-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .not-image {
      width: 100%;
      height: 100%;
      background-color: #5e5e66;
    }
  }
}

.gridtable-on-company {
  .rgt-wrapper {
    .rgt-container {
      grid-template-columns: 1fr 150px 100px auto !important;
    }

    @include mobile {
      .rgt-container {
        grid-template-columns: 1fr 40px 100px auto !important;
      }
    }
  }

  .field-actions {
    gap: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: start;
  }
}

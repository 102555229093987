.edit-piece-modal {
  // position: absolute;
  // top: 5%;
  // left: 60%;
  max-width: 30vw;
  border-radius: 8px !important;
  margin-left: 55vw;

  @include mobile {
    margin-top: 65px !important;
    position: unset;
    max-width: 100vw;
    margin-left: 10px;
  }

  > div {
    background-color: transparent !important;
  }

  .modal-content {
    height: 100%;
  }
}

.edit-piece-modal-container {
  border-radius: 8px !important;
  margin-bottom: 0px !important;

  @include mobile {
    padding-top: 0px !important;
    overflow: visible !important;
  }
}

.edit-piece-modal-input {
  margin: 0px !important;
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  @include mobile {
    padding-bottom: 5px;

    > div > input {
      height: 30px;
      font-size: 10px !important;
    }
  }

  > label {
    width: 65%;

    @include mobile {
      width: 50%;
      font-size: 10px;
    }
  }
}

.expand-image-button {
  cursor: pointer !important;
  padding: 9px 15px !important;
  margin-left: 248px;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #67676a;
  margin-bottom: 10px;

  width: 20% !important;

  align-self: flex-end;

  // background-color: transparent;

  // &.share {
  align-self: flex-end;
  // }

  @include mobile {
    width: 100%;

    span {
      font-size: 13px;
    }

    &.margin {
      margin-left: 0px;
    }
  }
}

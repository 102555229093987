.gridtable-for-add-parts {
    .rgt-container {
        grid-template-columns: 1fr auto 80px !important;
        .rgt-cell {
            min-height: 35px !important;
          }
        @include mobile {
            grid-template-columns: 1fr auto 80px !important;
        }
        .rgt-cell-header-actions {
            background-color: #67676a;
            border-bottom-right-radius: 16px;
          }
          .rgt-cell-actions {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          .rgt-cell-header-name {
            background-color: #67676a;
            border-bottom-left-radius: 16px;
          }
          .rgt-cell-name{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        .rgt-cell-header {

            &:nth-child(1) {
                .rgt-cell-header-inner {
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                    padding-left: 20px !important;
                }
            }

            &:nth-child(2) {
                .rgt-cell-header-inner {
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                }
            }

            &:nth-child(3) {
                .rgt-cell-header-inner {
                    border-top-right-radius: 16px !important;
                    border-bottom-right-radius: 16px !important;
                    padding-left: 20px !important;
                }
            }
        }
    }
}

.production-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-items: center;
    overflow: hidden;

    .bodyshop-info {
        display: flex;
        padding: 20px 25px;
        width: 100%;
        justify-content: end;
    }

    .production-body {
        background-color: #67676a;
        display: flex;
        // flex: 1 1;
        height: calc(100% - 15px);
        width: calc(100% - 30px);
        border-radius: 16px;
        padding: 10px;

        @include mobile {
            height: calc(100% - 40px);        
        }
    }
}
.prospera-modal {
  max-width: 70vw;
  // max-height: 65vh;
  background-color: #4b4b4d;
  display: flex;
  flex: 1;
  outline: none !important;

  @include mobile {
    max-width: 100%;
    margin-top: 60px;
  }

  .modal-content {
    height: 100vh;
  }

  &.expanded {
    right: 0;
    top: 0;
    margin: 0px;
    max-width: 70vw;
    float: right;

    .close {
      font-size: 24px;
      top: -2px;
      position: relative;
    }

    @include mobile {
      max-width: 100vw !important;
      overflow-y: hidden;
    }

    .modal-title {
      font-family: Inter, sans-serif;
      font-weight: bold;
      font-size: 18px;
      color: #8e92ac;
      text-align: left;
    }

    .modal-content {
      background-color: hsl(240, 1%, 30%) !important;
      border-radius: 0px;
      margin: 0px;
      width: 70vw;
      max-width: 60vw;
      height: 100vh;

      @include mobile {
        width: 100vw !important;
        max-width: 100vw;
        top: 64px;
        left: 0px;
        // height: calc(100vh - 64px);
        height: 100vh;
      }
    }

    .modal-body {
      // overflow: auto;

      @include mobile {
        // overflow: auto;
        background-color: #4b4b4d !important;
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 9999;
  display: flex;
  margin-left: 0 !important;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-body {
  display: contents !important;
  background-color: #4b4b4d !important;

  > div {
    width: 100%;
  }
}

.swal-close {
  background-color: #f16063;

  &:hover {
    background-color: #cf4655 !important;
  }
}

.swal-save {
  background-color: #66cb9f;

  &:hover {
    background-color: #4aae8c !important;
  }
}

.modal-footer-table {
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.header-buttons {
  display: flex;
}

.modal-service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: flex;
    flex-direction: row;
    margin-bottom: 120px;

    .form-switch {
      margin-bottom: 0px !important;
    }

    > div {
      flex: 1;
      align-self: center;
    }
  }
}

.expiration-date {
  display: flex;
  margin-bottom: 3px;
  align-items: center;
  grid-gap: 10px;

  @include mobile {
    grid-gap: 15px;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px !important;
  border-bottom: 1px solid #7a7a7e;
  padding: 0px;

  .header-title {
    display: flex;
    flex-direction: row;

    &.step {
      align-items: center;
    }
  }
}

.header-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-inline: 15px;
  justify-content: flex-end;
  // width: 82%;
}

.input-select {
  margin-inline: 5px;
  width: 25% !important;
  font-size: 14px;
  background-color: #f7f7fc;

  div[class*='-container'] {
    div[class*='-control'] {
      background-color: #f7f7fc !important;
      height: 40px;
    }

    div[class*='-control:active'] {
      background-color: #f7f7fc !important;
    }
  }

  &.dashboard {
    background-color: transparent;

    div[class*='-container'] {
      div[class*='-control'] {
        height: 100%;
      }
    }
  }
}

.input {
  margin-inline: 5px;
  width: 25% !important;
  font-size: 14px;
  background-color: #f7f7fc;

  div[class*='input-group'] {
    .form-control {
      height: 40px !important;
      color: white;
      background-color: #f7f7fc;
      text-transform: uppercase;
      cursor: text;
    }
  }
}

.button-icon {
  font-size: 20px;
}

.form-control {
  border-color: 1px solid black !important;
}

.modal-footer-button {
  cursor: pointer !important;
  padding: 9px 15px !important;
  margin-left: 20px;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-end;

  &.cancel {
    background-color: transparent;
  }

  &.margin-0 {
    margin-left: 0px;
  }

  // &.share {
  //   align-self: flex-end;
  // }

  @include mobile {
    width: 100%;

    span {
      font-size: 13px;
    }

    &.margin {
      margin-left: 0px;
    }
  }
}

.close-icon {
  cursor: pointer !important;
}

.text {
  &.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #25262a;
    margin-right: 0px !important;
  }

  &.subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #434449;
  }
}

.sub-title-check {
  font-weight: 400;
  font-size: 14px;
  color: #6d6e74;
  flex: 1;
}

.input-control {
  flex: 1;

  div[class*='input-group'] {
    .form-control {
      background-color: transparent !important;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      border: 0px;
      color: #f7f7fc !important;
      opacity: 1 !important;
      padding: 0px;
      &:focus {
        background-color: transparent !important;
      }
    }
  }
}

.div-bottom {
  margin-bottom: 40px;
}

.div-justify {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.btn-icon {
  border: 1px solid $primary-color !important;
  border-radius: 4px;
}

.mb-3 {
  margin: 5px !important;
  flex: 1px;

  @include mobile {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.dropzone {
  display: grid;
  // float: right;
  // margin-bottom: 5px !important;
  // right: 0;
  // // position: relative;
  // height: 120px;
  // width: 120px;
  // // top: 0px;
}

.square-value {
  background-color: #eff6ef;
  border-radius: 4px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  // margin-left: 15px;
}

.text-value {
  font-weight: 700;
  font-size: 14px;
  color: #6d6e74;
}

.icon-calendar {
  font-size: 25px;
  color: $primary-color;
  cursor: pointer;
  margin-right: 15px;
}

.icon-more {
  font-size: 20px;
  margin: 5px 15px 5px 15px;

  &.list {
    margin: 5px 10px 5px 10px;
  }

  // padding: 15px;
}

.div-row {
  display: flex;
  align-items: center;

  &.header {
    flex: 1;

    &.select {
      margin-bottom: 10px;
    }
  }

  &.select {
    justify-content: space-between;
  }
}

.default-size-input {
  width: 100%;
}

.div-btns {
  display: flex;
  width: 100%;
  bottom: 10px;
  position: absolute;
  flex: 1;
  justify-content: space-between;
  padding: 15px;

  button {
    height: 40px;
    padding: 0.375rem 0.75rem;
  }

  > div {
    flex: 1;
    text-align: right;
  }
}

.save-btn {
  border-radius: 4px;
  background: $success-color;

  &.finaly {
    border-radius: 4px;
    background: transparent;
    border: 1px solid $success-color;
    color: $success-color;
  }
}

.cancel-btn {
  border-radius: 4px;
  background: transparent;
  border: 1px solid #97989e;
  color: #97989e;
  margin-right: 15px;

  &:hover {
    color: #97989e;
    background-color: transparent;

    &.dark {
      background-color: $primary-color-dark;
    }
  }
}

.modal-footer-space {
  padding: 15px;
}

.btn-icon-list {
  background-color: $off-white-color !important;
  border-radius: 0;
  margin-left: 15px;
}

.generic-date-input {
  position: absolute !important;
  top: 190px;
  z-index: 9999;
}

.div-row-date {
  display: flex;
  cursor: pointer;
  align-items: center;

  &.finished {
    cursor: default;

    .icon-calendar {
      cursor: default;
    }
  }
}

.text-date {
  font-weight: 700;
  font-size: 14px;
  color: #6d6e74;
  margin-right: 15px;
}

.modal-container {
  background-color: #4b4b4d;
  // border: 0 !important;
  flex: 1;
  display: flex;
  padding: 25px;
  padding-top: 5px !important;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start; //space-between
  overflow-y: auto;

  @include mobile {
    overflow-y: auto !important;
  }

  /* Estilizando o scroll no Chrome */
  &::-webkit-scrollbar {
    width: 5px;
    /* Largura do scroll */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Cor de fundo da trilha do scroll */
  }

  &::-webkit-scrollbar-thumb {
    background: #70e000;
    /* Cor do "thumb" (barra de rolagem) */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Cor do "thumb" quando passar o mouse sobre ele */
  }

  @include mobile {
    // margin-top: 20px;
    margin-bottom: 60px !important;
    max-height: 100vh;
    height: calc(100vh - 65px) !important;
    width: 100%;
    padding: 10px;
  }
}

.modal-service-container {
  flex: 1;
  display: flex;
  padding: 25px;
  padding-top: 5px !important;
  flex-direction: column;
  // height: 100vh;
  width: 100%;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-y: scroll;

  // max-height: calc(100vh - 55px);
  @include mobile {
    padding: 10px !important;
  }
}

// .teste {
//     @include mobile {
//         flex: 1;
//         display: flex;
//         padding: 10px;
//         padding-top: 5px !important;
//         flex-direction: column;
//         height: 100vh;
//         width: 100%;
//         justify-content: flex-start; //space-between
//         overflow-y: scroll;
//     }
// }
// .ReactModal__Content {
//     background-color: #4b4b4d !important;
//     border: 0 !important;
//     max-width: 1000px;
//     min-width: 820px  ;
// }

.modal-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.desc-input {
  // margin-top: 25px;
  // margin: 10px;
  flex: 1;

  // @include mobile {
  //     margin: 0px;
  // }

  .default-input {
    color: white;
    font-size: 16px;
    resize: none;
    &:disabled,
    &[readonly] {
      background-color: #b3b3b31c !important;
      font-weight: 500;
      opacity: 1;
    }
  }

  textarea {
    height: auto;

    &:focus {
      box-shadow: none;
    }
  }

  &.company {
    .default-table {
      margin-bottom: 10px !important;

      .rgt-search-icon {
        color: red;
      }
    }

    .rgt-wrapper {
      min-height: auto;
      height: 200px;
      // max-height: 300px;

      @include mobile {
        height: 175px;
      }

      .rgt-container {
        grid-template-columns: 1fr 125px 60px auto !important;
      }
    }

    .rgt-cell-header:nth-child(2) {
      .rgt-cell-header-inner {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding: 0px;
      }
    }

    .rgt-cell-header:nth-child(3) {
      .rgt-cell-header-inner {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 0px 9px;
      }
    }
  }

  // overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    // display: none;
    width: 10px !important;
    background-color: #5e5e66;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: #5E5E66;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: #70e000;
    border-radius: 15px;
  }
}

div[class*='-MuiButtonBase-root-MuiPickersDay-root.Mui-selected'] {
  background-color: #70e000 !important;
}

div[class*='-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)'] {
  border: 1px solid rgb(255 255 255 / 60%);
}

svg[class*='-MuiSvgIcon'] {
  color: white;
}

div[class*='-MuiDayCalendar-weekContainer'] {
  > button {
    color: white;
  }
}

div[class*='-MuiDayCalendar-header'] {
  > span {
    color: white;
  }
}

div[class*='-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon'] {
  color: white;
}

div[class*='-MuiDateCalendar-root'] {
  color: white;
  background-color: #67676a;
}

div[class*='-MuiInputBase-input-MuiFilledInput-input.Mui-disabled'] {
  -webkit-text-fill-color: rgb(255 255 255 / 38%) !important;
}

div[class*='-menu'] {
  z-index: 99 !important;
}

.piece-paint {
  text-align: center;
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header:first-child
  .rgt-cell-header-inner {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  // padding-left: 10px !important;
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header:nth-child(2)
  .rgt-cell-header-inner {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  // padding: 10px !important;
}

.default-table
  .rgt-wrapper
  .rgt-container
  .rgt-cell-header
  .rgt-cell-header-inner {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  padding: 10px !important;
}

.desc-plate {
  display: flex;
  color: white !important;

  // margin-top: 30px;
  .form-label {
    color: white;
  }
}

.client-vehicles-table {
  padding: 0px;

  .rgt-wrapper {
    .rgt-container {
      .rgt-cell-actions {
        padding-left: 20px;
      }

      .rgt-wrapper {
        .rgt-container {
          .rgt-cell-actions {
            padding-left: 20px;
          }

          .rgt-cell:nth-child(3) {
            > div {
              padding-left: 10px;
            }
          }
        }
      }

      .rgt-cell:nth-child(3) {
        > div {
          padding-left: 10px;
        }
      }
    }
  }

  > div {
    > div {
      padding-top: 0.9vh;
      padding-bottom: 0.1vh;
      display: flex;

      > label {
        display: flex;
        flex: 1;
        margin-bottom: 0px;
        align-items: center;
      }
    }
  }
}

.entrada-plate {
  border-radius: 0px !important;
}

.text {
  color: white;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}

.add-button {
  display: inline-flex;
  height: 35px;
  align-items: center;
}

.add-button-position {
  // Needs to be adjusted later.
  text-align: end;
  // position: absolute;
  margin-left: 125.5vh;
  margin-top: 0.5vh;
}

.show-data {
  color: white;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.custom-radio {
  display: -webkit-box;
  color: white;
  margin-top: 20px !important;
}

.showFieldsText {
  color: white;
  margin-right: 12px;
  cursor: unset;
}

.icon-arrow {
  font-size: 170%;

  color: rgb(243, 238, 238);

  &.color {
    color: rgb(145, 145, 145);
  }
}

.close-Icon {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.generic-input {
  // max-height: 250px;
  // overflow-y: scroll;
  .label {
    color: white !important;
  }
}

.checklist-obs {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}

.check-box-input {
  color: #d9dbe9 !important;
  border-radius: 8px !important;
}

.input-group {
  border-radius: 8px !important;
  // align-items: flex-end;
  cursor: text !important;

  > textarea {
    resize: none !important;
  }
}

div[class*='-indicatorSeparator'] {
  display: none !important;
}

.input-group-text {
  background: #67676a;
  border: 0;
  border-radius: 8px;
  padding: 0;
  padding-inline: 10px !important;
  height: 40px !important;
}

.default-input {
  background: #67676a;
  color: white;
  border: 0 !important;
  &:disabled,
  &[readonly] {
    background-color: #b3b3b31c !important;
    font-weight: 500;
    opacity: 1;
  }
}

.desc-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @include mobile {
    // margin-bottom: 15px;
    flex-direction: column;
    align-items: stretch;
    margin-inline: 10px;
  }

  .generic-input {
    flex: 1;

    @include mobile {
      margin: 0px !important;
      margin-bottom: 10px !important;
    }

    &:first-child {
      margin-right: 10px;

      @include mobile {
        margin-right: 5px;
      }
    }

    &:last-child {
      margin-left: 10px;

      @include mobile {
        margin-left: 5px;
      }
    }
  }

  &.last {
    @include mobile {
      .generic-input {
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.div-name-cnpj {
  // margin-right: 20px;
  margin-left: 0px;
  flex: 2; // width: 50%

  @include mobile {
    margin-left: 0px;
  }
}

.address-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .generic-input {
    flex: 1;

    &:first-child {
      margin-right: 10px;

      @include mobile {
        margin-right: 0px;
      }
    }

    &:last-child {
      margin-left: 10px;

      @include mobile {
        margin-left: 0px;
      }
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: inherit;
  }
}

.search-Icon {
  color: white;
}

.percent-label {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.row {
  flex-direction: row;
  display: flex;
}

.col {
  flex-direction: column;
  display: flex;
}

.add-btn {
  background-color: #d9dbe9 !important;
  color: white;
  height: 40px;
  width: 45px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.modal-button-add {
  background-color: #d9dbe9 !important;
  color: white;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.input-size {
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: flex-end;
}

::placeholder {
  color: white !important;
}

// .modalNewService {
//     background-color: #4B4B4D;
// }

.sub-services {
  margin-top: 45px;
  overflow: hidden;
}

.sub-services-render {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}

.sub-services-info {
  color: #f7f7fc;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 40px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  border-radius: 8px;
  width: 70%;
  padding: 15px;
  background-color: #67676a;
}

.delete-btn-modal {
  background-color: #ffaaa4 !important;
  color: #e82314;
  height: 40px;
  width: 45px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    opacity: 0.6;
  }
}

.button-icon-cancel {
  color: #ffffff !important;
  font-size: 20px;
}

.cancel {
  color: white !important;
  border: 1px white solid !important;

  &:hover {
    background-color: #67676a !important;
  }
}

.width-correction {
  gap: 15px;

  > div {
    width: 100%;
  }
}

.company-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  > input {
    width: 15px;
  }
}

.main-upper-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 2;

  @include mobile {
    display: flex;
    // flex-direction: column;
  }
}

.main-upper-div-for-checklist {
  align-items: center;
  flex-direction: row;
  flex: 2;
}

.main-div-inputs {
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}

.lower-div-for-value-hour-chart {
  padding-top: 25px;
  width: 100%;

  > div {
    width: 100%;
  }

  > div > div {
    width: 100%;
  }
}

.inside-lower-div-value-hour-chart {
  display: flex;
}

.div-name-vehicle-infos {
  // margin-right: 20px;
  margin-left: 0px;
  margin-right: 10px;
  flex: 0.8; // width: 50%

  @include mobile {
    margin-left: 0px;
  }
}

.div-dropzone {
  // width: 50%
  flex: 0.4;
  // padding-left: 20px;
  // margin-right: 20px;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // border: 1px solid #e1e1e1;
  /* flex-wrap: wrap; */
  /* align-content: stretch; */
}

.react-tabs__tab-list {
  margin-top: 15px !important;
  // border-bottom: 1px solid #aaa;
  border: 0 !important;
  display: flex;
  margin-bottom: 0px !important;
  padding: 0;
  color: white;
  align-content: center;
  flex-wrap: wrap;
  // justify-content: center;
}

.react-tabs__tab {
  display: inline-block;
  // border: 1px solid transparent;
  background-color: #67676a;
  border-bottom: none;
  bottom: -5px;
  left: -0.5px;
  position: relative;
  text-align: center;
  margin: 0.5px;
  // border-radius: 4px;
  /* list-style: none; */
  padding: 6px 12px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  svg {
    margin-bottom: 2px;
  }

  @include mobile {
    text {
      display: none;
    }
  }
}

.react-tabs__tab--selected {
  background: #70e000 !important;
  border-color: #33343570 !important;
  text-align: center;
  color: white !important;
  background-color: #33343570 !important;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab:focus:after {
  // content: '';
  position: absolute;
  height: 0px !important;
  left: 0px !important;
  right: 0px Impor !important;
  bottom: 0px !important;
  // background: #fff;
}

.react-tabs__tab-panel {
  background-color: #33343570 !important;
  border-top-left-radius: 0px !important;
  border-radius: 10px;
  padding: 5px;
  margin-top: 0px;

  @include mobile {
    padding: 0px !important;
    padding-bottom: 50px;
  }
}

.main-tab .react-tabs__tab-panel--selected {
  display: block;
  background-color: #33343570;
  border-radius: 0px 8px 8px 8px;
  // margin-right: 20px;
}

.div-dropzone-vehicles {
  // padding-top: 10px;
  // margin-left: 22px;
}

.void-class {
  div[class*='-option'] {
    background-color: #67676a !important;
    color: white !important;
    text-transform: uppercase;
  }

  div[class*='-control'] {
    box-shadow: 0 0 0 0px #67676a !important;
    border-color: #67676a !important;
    color: white !important;
    background-color: #67676a;
    font-size: 13px;
  }

  div[class*='-singleValue'] {
    color: white !important;
    background-color: #67676a;
    text-transform: uppercase;

    // color: #f0f0f0 !important;
  }

  div[class*='-menu'] {
    color: white !important;
    background-color: #67676a;

    // color: #f0f0f0 !important;
  }
}

// div[class*="-option"] {

//     background-color: #67676A!important;
//     color: white !important;

// }

// div[class*="-control"] {

//     box-shadow: 0 0 0 0px #67676A !important;
//     border-color: #67676A !important;
//     color: white !important;
//     background-color: #67676A;

// }

// div[class*="-singleValue"] {

//     color: white !important;
//     background-color: #67676A;

//     // color: #f0f0f0 !important;

// }

// div[class*="-menu"] {

//     color: white !important;
//     background-color: #67676A;

//     // color: #f0f0f0 !important;

// }

.checklist-items {
  padding-bottom: 15px !important;
  display: flex;
  width: 100%;
}

.production-phase-main-upper-div {
  display: flex;
  flex: 2;
  flex-direction: row;
}

.new-checklist-description {
  margin: 5px 0px 0px 0px !important;

  @include mobile {
    margin: 0px !important;
  }

  > div {
    @include mobile {
      width: auto;
    }
  }
}

.text-observation-min {
  min-height: 16vh !important;
}

.text-observation-min2 {
  min-height: 16vh !important;
}

.percentage-value {
  margin: 0px 0px 0px 5px;

  @include mobile {
    margin: 0px;
  }

  > div {
    width: 130px;

    @include mobile {
      width: auto;
    }
  }
}

.percentage-content {
  > div {
    @include mobile {
      width: 100% !important;
      padding-right: 10px !important;
    }
  }
}

.description-add-button-placement {
  padding-left: 20px;
  padding-top: 37px;
  padding-right: 5px;

  @include mobile {
    padding-left: 10px;
    padding-top: 31px;
    // padding-right: 5px;
  }
}

.description-add-button {
  color: white !important;
  border: 1px solid black;
  background-color: $primary-color !important;
  height: 40px !important;
  width: 50px !important;
  justify-content: center;
  align-items: center;
  display: flex;

  > span {
    margin: 0px !important;
    font-size: 20px !important;
  }
}

.new-checklist-item {
  width: 125%;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-wrapper input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.checkbox-wrapper label {
  font-size: 18px;
}

.value-hour-input {
  background-color: #67676a !important;
  border: solid #67676a;
  border-radius: 10px;
  text-align: center;
  height: 40px;

  > div > input {
    background-color: #67676a;
    font-size: 15px;
  }
}

.price-placement {
  padding-top: 15px;
}

.div-production-description {
  width: 100%;
}

.div-hour-value-description {
  width: 100%;

  .generic-input,
  .mb-3 {
    margin: 0px !important;
  }
}

.value-hour-price-input {
  width: 140px;

  &.mb-3 {
    margin-left: 10px !important;
  }

  > div > input {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  @include mobile {
    width: 100px;
  }
}

.service-description {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  gap: 20px;

  > div > div {
    @include mobile {
      width: 100%;
    }
  }

  .generic-input {
    margin: 0px !important;
  }
}

.small-size-input-frequency {
  padding-bottom: 10px;
  padding-top: 10px;

  > div {
    width: 100%;
    display: flex;
    text-align-last: center;
    // padding-left: 19px;
  }

  > div > input {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: white;

    @include mobile {
      padding: 2px 0px 0px 0px !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.service-size-content {
  padding-top: 10px;
  display: grid;
  gap: 5px;

  @include mobile {
    display: grid;
    gap: 5px;
    padding-top: 26px !important;
  }

  > div {
    padding: 5px 0px 5px 0px !important;
  }
}

.first-service-size-content {
  display: flex;
  width: 100%;
  padding-top: 0px;

  @include mobile {
    width: 100%;
    padding-left: 10px;
    flex-direction: column;
  }
  > div {
    margin: 0px !important;
  }
}
.first-service-size-content-row {
  display: flex;
  width: 100%;
  padding-top: 0px;

  @include mobile {
    width: 100%;
    padding-left: 10px;
  }
  > div {
    margin: 0px !important;
  }
}
.mobile-line-modal-service {
  display: flex;
  justify-content: center;
  width: 5%;
  padding-left: 10px;

  @media (min-width: 768px) {
    padding-left: 0px;
  }
}

.middle-service-size-content {
  display: flex;
  width: 100%;
  padding-top: 0px;

  @include mobile {
    width: 100%;
    padding-left: 10px;
  }

  > div {
    margin: 0px !important;
  }

  > div > div {
    @include mobile {
      width: 100% !important;
    }
  }
}

.last-service-size-content {
  display: flex;
  width: 100%;
  padding-top: 0px;

  @include mobile {
    width: 100%;
    padding-left: 10px;
  }

  > div {
    width: 100%;
    margin: 0px !important;
  }

  > div > div {
    @include mobile {
      width: 100% !important;
    }
  }
}

div[class*='-MuiInputBase-input-MuiFilledInput-input'] {
  -webkit-text-fill-color: white !important;
}

.normal-size-input {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 8px;
  padding-right: 5px;
  // display: grid !important;
  // place-items: center !important;

  @include mobile {
    padding-top: 10px;
  }

  > div {
    width: 100% !important;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.normal-size-result {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 8px;

  @include mobile {
    padding-top: 10px;
  }

  opacity: 0.5 !important;

  > div {
    width: 100% !important;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.message-area-min {
  margin: 0px !important;

  @include mobile {
    > div {
      min-width: 81vw;
    }
  }

  > div > textarea {
    min-height: 200px !important;
    height: 200px;
  }
}

.lower-aesthetic-div {
  // width: 67vw;
}

.item-title {
  border-radius: 5px;
  // padding: 10px;
  border: 0px;
  outline: 0;
  width: 100%;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: white;
  background-color: #67676a;
  padding: 10px;
  // padding-top: 10px;
  // padding-bottom: 0px;

  &:focus {
    color: white;
    background-color: #67676a;
  }

  @include mobile {
    padding-top: 10px;
    // padding-bottom: 30px;
  }
}

.service-img-placement {
  text-align: center;

  img {
    width: 85%;
  }

  > div {
    margin: 0px !important;
  }

  > div > section {
    min-width: 100% !important;
    min-height: 300px !important;

    @include mobile {
      min-width: 107% !important;
    }
  }

  > div > section > div {
    min-height: 270px !important;
  }

  > div > section > div > img {
    // min-width: auto !important;
    // min-height: 260px !important;
    max-width: 100% !important;
    max-height: 260px !important;
  }
}

.btn {
  .danger {
    border-color: red;
    background-color: red;
    color: black;

    &:focus {
      border-color: red;
      background: red;
      color: black;
    }
  }

  .confirm {
    border-color: green;
    background-color: green;
    color: black;

    &:focus {
      border-color: green;
      background: green;
      color: black;
    }
  }
}

.service-gap {
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @include mobile {
    width: 100%;
    padding-top: 20px;
  }
}

.hour-input {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 8px;
  padding-right: 5px;

  @include mobile {
    padding-top: 10px;
  }

  > div {
    width: 100% !important;
    display: flex;

    @include mobile {
      width: 100%;
    }
  }

  > div > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.last-hour-input {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 8px;

  opacity: 0.5 !important;

  > div {
    width: 100% !important;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    text-align: center;
  }
}

.repeat-text {
  padding-left: 80px;

  @include mobile {
    padding-left: 5px;
  }
}

.subservice-item {
  margin: 5px 5px 0px 0px !important;
  padding-top: 5px;
  padding-right: 0px;

  @include mobile {
    padding-top: 0px;
    padding-right: 0px;
  }
}

.subservice-percent {
  margin: 10px 0px 0px 0px !important;

  @include mobile {
    margin: 5px 0px 0px 0px !important;
  }
}

.delete-service-placement {
  padding-top: 10px !important;
  padding-right: 0px !important;
  padding-left: 5px !important;

  @include mobile {
    padding-top: 5px !important;
  }
}

.percentage-button-placement {
  margin-top: 30px;
  margin-left: 5px;
}

.service-type-description {
  margin: 0rem !important;
}

.service-type-description-placement {
  width: 80%;
}

.service-type-percentage-placement {
  width: 25%;
  margin-right: 5px;
  margin-left: 5px;

  @include mobile {
    width: 23%;
  }
}

.small-size-input {
  &.mb-3 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  &.step {
    width: auto !important;
  }

  @include mobile {
    &.mb-3 {
      padding-top: 0px !important;
      padding-bottom: 4px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  > div {
    width: 50px;
    display: flex;
  }

  > div > input {
    height: 40px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

.dropdown-item {
  text-align-last: center !important;

  &:active {
    color: black;
    text-decoration: none;
    background-color: #70e000;
  }
}

.row-input-check {
  padding-right: 5px;

  > button {
    width: 40px !important;
    height: 30px !important;
    display: flex;
    background-color: transparent !important;
    border-color: rgb(255, 170, 164);
  }
}

.mobile-gap {
  display: flex;
  width: 100%;
  gap: 40px;

  @include mobile {
    gap: 0px;
  }
}

.select-addParts {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: normal;

  > div > div {
    border: solid !important;
    border-color: #67676a !important;
    color: white;
    background-color: rgb(103, 103, 106);
    box-shadow: none !important;

    div[class*='-option']:hover {
      background-color: rgba(112, 224, 0, 0.05);
    }

    div[class*='-option']:active {
      background-color: rgba(112, 224, 0, 0.1);
    }

    input {
      text-transform: uppercase;
    }

    > div > div {
      color: white;
    }
  }
}

.button-add-table {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #67676a !important;
  text-align: center;
  width: 40px;
  padding-top: 7px;

  &:hover {
    background-color: #4aae8c;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:active {
    background-color: #4aae8c;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.gridtable-for-external-budget {
  .rgt-container {
    .rgt-cell {
      min-height: 35px !important;
    }

    .rgt-cell-header:nth-child(1) .rgt-cell-header-inner {
      padding-left: 20px !important;
    }

    .rgt-cell-header:nth-child(2) .rgt-cell-header-inner {
      padding-left: 20px;
    }

    .rgt-cell-header {
      border-top-right-radius: 16px !important;
      border-bottom-right-radius: 16px !important;

      &:nth-child(5) {
        .rgt-cell-header-inner {
          border-top-right-radius: 16px !important;
          border-bottom-right-radius: 16px !important;
          padding-left: 20px !important;
        }
      }
    }
  }
}

div[class*='-control'] {
  min-height: 40px !important;
}

.hour-quantity-service {
  color: white;
  font-weight: 500;
  font-size: 14;
}

.value-quantity-service {
  color: white;
  font-weight: 500;
  font-size: 14;
}

.total-quantity-service {
  color: white;
  font-weight: 500;
  font-size: 14;
}

.content-table {
  display: flex;
  grid-gap: 20%;
}

.repeat-days-modal {
  @include mobile {
    flex-flow: column;
  }
}

.notification-days-mobile {
  display: flex;
  flex-flow: row;
}

.service-part-type {
  @include mobile {
    > div {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}

.parts_input {
  width: 100%;
  margin: 0px !important;
}

.client-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.top-info {
    /* flex: 1 1; */
    display: flex;
    /* height: 100px; */
    padding: 30px;
    width: 100%;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
}

.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}


.top-info-text {
    color: white;
    margin-left: 55px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    letter-spacing: 0.25px;
    margin-right: 25px;
}

.search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.service-type{
    margin-left: 35px;
    min-width: 185px;
}

.search-Icon {
    color: white;
}

.module-info {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: 85%;
    width: 95%;
    border-radius: 16px;
    flex-direction: column;
    padding: 20px;
}

.description {
    display: flex;
    flex-direction: row;
    background-color: #5e5e66;
    border-radius: 8px;
    padding-inline: 50px;
    justify-content: space-between;
}

.description-text {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0px;
    padding: 12px;
    color: #d9dbe9;
   
}

.order{
    margin-left: -40px;
}

.grade {
    margin-left: 15px;
}

.title-left{
    margin-left: 50px;
}

.input {
    width: 100% !important;
    background-color: transparent;
}

.input-label {
    color: white;
}
.render-text-title-left{
    margin-left: 75px !important;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color: white;

}

.render-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px #7a7a7e solid;
    padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.render-icons {
    color: #82ff05;
    background-color: #e4ffca;
    font-size: 30px;
    padding: 5px;
    border-radius: 4px;
}

.render-text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color: white;
    margin-left: 20px;
}

.render-icon-text {
    display: flex;
    align-items: center;
}

.edit-btn {
    color: #a0a3bd;
    // border: 1px #a0a3bd solid;
    border-radius: 8px;
    background-color: #d9dbe9;
    font-size: 30px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 15px;
    &:hover {
        // background-color: #d9dbe9;
        opacity: 0.5;
    }
}

.delete-btn {
    color: #e82314;
    // border: 1px #E82314 solid;
    border-radius: 8px;
    background-color: #ffaaa4;
    font-size: 30px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 50px;
    &:hover {
        // background-color: #d9dbe9;
        opacity: 0.5;
    }
}

.steps-title {
    display: flex;
    flex-direction: row;
}

.css-1s2u09g-control {
    background: #67676A !important;
    border: 0 !important;
    border-radius: 8px !important;
}

.css-1pahdxg-control {
    background: #67676A !important;
    border: 0 !important;
    border-radius: 8px !important;
}

.css-26l3qy-menu {
    background: #67676A !important;
    &:hover {
        background: #67676A !important;
        opacity: 0.8 !important;
    }
}

.css-1okebmr-indicatorSeparator {
    width: 0 !important;
}

.css-tj5bde-Svg {
    color:#B5B5BD ;
}

.css-qc6sy-singleValue {
    color: #F7F7FC !important;
}

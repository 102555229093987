.admin-screen-container {
  overflow-y: scroll !important;

  @include mobile {
    overflow: scroll !important;
  }

  .admin-screen-main {
    height: 90vh !important;

    @include mobile {
      height: 90vh !important;
    }
  }
}

.dashboard-container {
  flex-direction: column;
  flex-direction: row;
  display: flex;
  // flex-wrap: wrap;
  margin: 10px;
  margin-top: 30px;

  .list-indicators {
    display: flex;
    flex-direction: row;
    margin: 10px;

    .badge-indicator {
      margin-right: 10px;
      font-size: 14px;
      padding-inline: 12px;
      cursor: pointer;

      &.selected {
        background-color: $primary-color-dark !important;
      }
    }
  }

  .dashboard-top-bar {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px;
    display: flex;
    flex-direction: row-reverse;
  }

  .dashboard-first-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
  }

  .dashboard-card-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;

      h6 {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 0px;
      }

      text {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .graph-card {
    flex: 1;
  }

  .ball {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .dashboard-last-itens {
    flex: 2 !important;
  }

  .not-data-graphs {
    color: $error-color-dark;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .default-search {
    margin-inline: 5px;
  }

  .dashboard-search {
    display: flex;
    flex-direction: row;
  }

  .last-insert-row {
    display: flex;
    flex-direction: row;
    flex: 1;

    .insert-columns {
      flex-direction: column;
      flex: 1;

      &.margin {
        margin-bottom: 15px;
      }
    }

    .cash {
      font-weight: 600;
      margin-bottom: 0;

      &.success {
        color: $success-color;
      }

      &.danger {
        color: $error-color;
      }
    }

    .info-name {
      font-size: 14px;
      margin-bottom: 0 !important;
      color: $text-body-color;
      flex: 1;
    }

    .div-dot {
      margin-right: 30px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .line-dashed {
        border: 1px dashed #a0a3bd;
        width: 1px;
        height: 80%;

        &.mini {
          height: 10px;

          &.hidden {
            border: none;
          }
        }
      }

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #a0a3bd;
        // margin-top: 10px;
      }
    }
  }

  .dashboard-row-calendar {
    display: flex;
    align-items: center;

    &.margin {
      margin-top: 15px;
      margin-bottom: 5px;
    }

    &.justify {
      justify-content: space-between;
    }

    .last-itens {
      padding-inline: 10px;
    }
  }

  .dashboard-col-calendar {
    display: flex;
    flex-direction: column;

    &.margin {
      margin-top: 15px;
    }

    .link-button {
      text-align: start;
      margin-top: 5px;
    }
  }

  .dashboard-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    &.bank {
      margin-bottom: 20px;
    }
  }

  .dashboard-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 1440px) {
      flex: 3 1 150px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      flex: 3 1 185px;
    }

    @media (max-width: 1365px) {
      flex: 3 1 330px;
    }

    @include tablet {
      flex: 3 1 330px;
    }
  }

  .dashboard-columns-labels {
    display: flex;
    flex-direction: column;
  }

  .preview-entry {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: $text-body-color;
    font-weight: 400;
  }

  .label-entry {
    margin-bottom: 0 !important;
  }

  .preview-entry-value {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: $text-body-color;
    // margin-left: 20px;
    flex: 1;
  }

  .preview-entry-info {
    font-weight: 400;
    font-size: 12px;
    color: $text-label-color;
    margin-bottom: 0 !important;
    width: 70px;
  }

  .bank-money {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    margin-left: 15px;
  }

  .bank-add-btn {
    margin-left: 0;
  }

  .bank-title {
    .card-row {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      justify-content: space-between;
    }
  }

  .bank-calendar {
    background-color: #ffffff;

    .rdrDayToday {
      .rdrDayNumber {
        span::after {
          display: none;
        }
      }
    }

    .rdrDay {
      cursor: default !important;

      .rdrSelected {
        background: $primary-color-light;
        border-radius: 4px;
        height: 36px;
        right: 0px;
        bottom: 0px;
        top: 2px;
      }

      .rdrDayStartPreview,
      .rdrDayEndPreview {
        border: 1px solid $primary-color;
        border-radius: 4px;
        height: 36px;
        right: 0px;
        bottom: 0px;
        top: 2px;
      }

      &.rdrDayHovered {
        .rdrDayNumber::after {
          display: none;
        }
      }
    }

    .rdrMonthAndYearWrapper {
      display: none;
    }

    .rdrMonth {
      padding: 0px;
    }
  }

  .dot {
    &.outgoing {
      height: 5px;
      width: 5px;
      border-radius: 100%;
      background: #ed2d2d;
    }

    &.entry {
      height: 5px;
      width: 5px;
      border-radius: 100%;
      background: #00ba88;
    }
  }

  .calendar-div {
    background-color: #ffffff !important;

    .info {
      flex: 0;
      background-color: #ffffff;
    }
  }

  .bank-name {
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  .bank-value {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: $text-body-color;
  }

  .info-line {
    height: 0.1px;
    background-color: #d9dbe9;
    border-radius: 100px;
    margin-bottom: 5px !important;
    margin-top: 5px;
  }

  .info {
    background-color: $off-white-color;
    flex: 1;
    // margin: 10px;
    border-radius: 4px;
    padding: 20px;
    // margin-bottom: 20px;

    .card-row {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    @media (min-width: 1440px) and (max-width: 1440px) {
      flex: 1 1 150px;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
      flex: 1 1 175px;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      flex: 1 1 300px;
    }

    @include tablet {
      flex: 1 1 330px;
    }

    @include mobile {
      flex: 1 1 330px;
    }

    h6 {
      // letter-spacing: 0.75px;
      line-height: 28px;
      margin-bottom: 15px;
    }

    span {
      font-size: 16px;
      // letter-spacing: 0.75px;
    }

    .date {
      margin-top: 10px;

      svg {
        background-color: $primary-color-light;
        color: $primary-color;
        border-radius: 4px;
        padding: 8px;
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }

    .progress {
      height: 6px;
      margin-bottom: 10px;

      &.bar-success {
        background-color: $success-color-light;

        .progress-bar {
          background-color: $success-color;
        }
      }

      &.bar-danger {
        background-color: $error-color-light;

        .progress-bar {
          background-color: $error-color;
        }
      }
    }

    .value {
      justify-content: space-between;
      display: flex;
      margin-top: 5px;

      span {
        font-size: 12px;
        color: $text-body-color;
      }
    }

    .last-itens {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #6e7191;
      margin: 0 !important;
    }

    .bank {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-direction: row;

      &.col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .div-icons {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
      }

      .icons {
        svg {
          background-color: $background-color;
          display: flex;
          margin: 5px;
          font-size: 20px;
          align-items: center;
          padding: 10px;
        }

        &.edit {
          svg {
            margin: 10px !important;
            color: $text-label-color;
          }
        }

        &.trash {
          svg {
            margin: 0px !important;
            color: $error-color;
          }
        }
      }

      svg {
        background-color: $primary-color-light;
        color: $primary-color;
        border-radius: 8px;
        padding: 10px;
        width: 42px;
        height: 42px;
        margin-right: 20px;
      }

      h4 {
        margin-bottom: 0px;
        font-weight: 600;
        color: $primary-color;
        // letter-spacing: 0.75px;
      }
    }

    .cash-forecast {
      color: $text-label-color;
      // letter-spacing: 0.75px;
    }
  }
}

.dashboard-second-row {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  // margin: 10px;
  flex: 1;
}

.div-list-card {
  margin: 10px;
}

.div-user {
  // width: 100%;
  margin: 10px;
  flex: 1;
}

.dashboard-loading {
  display: flex;
  height: calc(100% - 71px);
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading-data {
    font-size: 18px;
    font-weight: bold;
    color: $primary-color-dark;
  }
}

.card-container {
  background-color: #4b4b4d;
  margin-right: 15px;
  border-radius: 5px;
  width: 40%;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  min-width: 425px;
}

.card-contain {
  width: 90%;
  min-width: 375px;
  margin: 10px;
  text-align: center;
  background-color: transparent;
  border-radius: 5px;
}

.title-div {
  background-color: #b70d00;
  border-radius: 5px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.card-title {
  margin-bottom: 0;
  color: white;
}

.card-cell {
  background-color: #67676a;
  display: flex;
  flex-direction: row;
  min-width: 375px;
  border-radius: 15px;
  padding-right: 10px;
  max-height: 170px;
  justify-content: space-between;
  align-items: center;
}

.card-cell-image {
  display: flex;
  align-items: center;
}

.card-cell-info {
  margin-left: 10px;
  padding-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.os-cod {
  font-size: 13px;
  font-weight: 500;
  color: white;
}

.info-text {
  font-size: 14px;
  font-weight: 400;
  color: #4e4b66;
}

.service-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.service-info-type {
  color: white;
  background-color: #b70d00;
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}

.dashboard-placement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    justify-content: flex-start;
  }

  // overflow-y: scroll !important;
  &::-webkit-scrollbar-track {
    background: #00000000 !important;
  }

  &::-webkit-scrollbar {
    // width: 0px;
    // height: 6px;
    // background: #70e000 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .admin-screen-main {
    height: calc(100vh - 170px) !important;
  }

  .dashboard-home {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 20px;
    flex-direction: column;

    // margin-inline: 15px;
    @include mobile {
      padding: 15px;
      // height: 100vh;
      justify-content: flex-start;
    }
  }
}

// .admin-screen-content {
//   overflow-y: scroll !important;
//   &::-webkit-scrollbar-track {
//     background: #00000000 !important;
//   }

//   &::-webkit-scrollbar {
//     width: 0px;
//     height: 6px;
//     background: #00000000 !important;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #00000000 !important;
//   }
//   .admin-screen-main {
//     height: calc(100vh - 170px) !important;
//   }
// }

.dashboard-logo {
  width: 260px;

  @include mobile {
    width: 170px;
  }
}

.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root.Mui-error::before {
  border-bottom-color: #00000000 !important;
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 10px !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: $primary-color !important;
}

.css-innj4t-MuiPickersYear-yearButton.Mui-selected {
  background-color: $primary-color !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: white !important;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  color: white !important;
}

.css-1g7nc1s-MuiPickersLayout-root {
  background-color: #67676a !important;
}

.css-1hbyad5-MuiTypography-root {
  color: white !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: white !important;
}

.deactivated-button {
  background-color: #515154;
  display: flex;
  width: 200px;
  height: 50px !important;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: not-allowed;

  @include mobile {
    width: 100%;
  }

  >svg {
    color: white;
    font-size: 20px;
  }

  >text {
    color: white;
  }
}

.location-button {
  background-color: #67676a;
  display: flex;
  width: 200px;
  height: 50px !important;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  @include mobile {
    width: 100%;
  }

  &:active {
    background-color: #67676a;
    color: lawngreen;
    border: 1px solid lawngreen;

    &:hover {
      background-color: #67676a;
      color: lawngreen;
      border: 1px solid lawngreen;
    }

    >svg {
      color: lawngreen;
    }

    >text {
      color: lawngreen;
    }
  }

  >svg {
    color: white;
    font-size: 20px;
  }

  >text {
    color: white;
  }
}

.image-button-logo {
  width: 100%;
  height: 80%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @include mobile {
    width: 230px;
  }
}

.image-text-button {
  background-color: #67676a;
  display: flex;
  width: 230px;
  height: 180px !important;
  border-radius: 16px;
  align-items: center;
  justify-content: start;
  gap: 10px;
  cursor: pointer;
  flex-direction: column;

  @include mobile {
    width: 100%;
  }

  &:active {
    background-color: #67676a;
    color: lawngreen;
    border: 1px solid lawngreen;

    &:hover {
      background-color: #67676a;
      color: lawngreen;
      border: 1px solid lawngreen;
    }

    >svg {
      color: lawngreen;
    }

    >text {
      color: lawngreen;
    }
  }

  >svg {
    color: white;
    font-size: 20px;
  }

  >text {
    color: white;
  }
}

.image-text-button:hover {
  transform: scale(1.05);
  /* Efeito de zoom ao passar o mouse */
}

.twin-buttons {
  display: flex;
  gap: 10px;

  @include mobile {
    width: 100%;
  }
}

.dashboard-button-placement {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;

  @include mobile {
    display: contents;
  }
}

.dashboard-grid-placement {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;

  @include mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
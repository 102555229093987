.div-icon-dropdown {
  position: absolute;
  background-color: white;
  height: 220px;
  padding: 5px;
  width: 240px;
  // border-radius: 10px;
  z-index: 99999;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);

  @media (max-width: 400px) {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 270px;
  }

  &.mini {
    height: 215px;
  }

  &.dropzone {
    height: auto;
    min-width: 300px;
    border-radius: 8px;
  }

  .div-dropdown-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;

    text {
      flex: 1;
      text-align: center;
      margin-right: 26px;
    }

    svg {
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .tag-select-color {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    margin-inline: 2px;
  }

  .div-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
    border-radius: 50px;

    > div {
      > div[class*='-option'] {
        width: 35px;
      }
    }

    &:hover {
      border-radius: 50px;
      background-color: #ddeefa;
    }

    .div-dropdown-tag {
      display: flex;
      justify-content: space-between;
      width: 128px;

      .tag-dropdown {
        width: fit-content;
        padding-inline: 10px;
        border-radius: 30px;
        height: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 125px;
      }
    }
  }

  .div-dropdown-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
    border-radius: 50px;
    background: #ddeefa;

    &:hover {
      border-radius: 50px;
      background: rgba(113, 128, 150, 0.15);
    }

    .div-dropdown-tag {
      display: flex;
      justify-content: space-between;

      .tag-dropdown {
        width: fit-content;
        padding-inline: 10px;
        border-radius: 30px;
        height: 26px;
      }
    }
  }

  div[class*='-MenuList'] {
    height: 170px !important;
    overflow-y: scroll !important;
  }

  div[class*='-option'] {
    div {
      &:hover {
        color: $primary-color;
        background: transparent;
      }
    }
  }
}

.div-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  border-radius: 50px;

  > div {
    > div[class*='-option'] {
      width: 35px;
    }
  }

  &:hover {
    border-radius: 50px;
    background-color: #ddeefa;
  }

  .div-dropdown-tag {
    display: flex;
    justify-content: space-between;
    width: 128px;

    .tag-dropdown {
      width: fit-content;
      padding-inline: 10px;
      border-radius: 30px;
      height: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 125px;
    }
  }
}

.label-users {
  div[class*='input-group'] {
    .form-control {
      background-color: #eff6ef;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #f7f7fc !important;
      // color: #97989E;
      opacity: 1 !important;
      // padding: 0px;
    }
  }
}

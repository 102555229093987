table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  font-size: 12px;
  text-align: left;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}

.print-padding-mobile {
  padding: 60px 40px 0px 40px;

  @include mobile {
    padding: 5px 10px 0px 10px;
  }
}

.print-logo {
  padding: 5px;
  width: 125px;
  max-width: 125px !important;
  min-width: 125px !important;
  height: 125px;
  max-height: 125px !important;
  min-height: 125px !important;
  object-fit: cover;
}

//   tr:nth-child(even) {
//     background-color: #dddddd;
//   }
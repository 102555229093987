.aesthetics-services-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.budget-label-plus-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.budget-action-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.mt-2-5_d-flex {
  margin-top: 10px;
  display: flex;
}

.column-service-renderer-container {
  gap: 5px;
  display: flex;
  align-items: center;
  padding-left: 8px;
}
.column-service {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 4;
}

.column-service-packages {
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 99%;
  height: 1px;
  background-color: #fff;
}

.inputHeight {
  height: 20px;
}

.input-border-error {
  border: 0.5px solid red !important;
  border-radius: 4px;
}

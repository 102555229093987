.custom-select {
  position: relative;
  margin-bottom: 5px;
}

.select-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0px 0 12px;
  border-radius: 5px;
  height: 40px;
  background-color: #67676a;
  span {
    color: white;
  }
}
.dropdown-custom-select {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #67676a;
  width: 140px;
  color: white;
}
.dropdown-input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.dropdown-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  :hover {
    background-color: gray;
  }
}
.dropdown-li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selected-countrie {
  background-color: #d3d3d350;
  font-weight: bold;
}

@import 'styles/theme.scss';
@import 'styles/responsive.scss';
@import 'styles/tipography.scss';
@import 'styles/shadow.scss';

//Components
@import 'components/Input/input.scss';
@import 'components/Button/button.scss';
@import 'components/PieChart/pieChart.scss';
@import 'components/Chart/chart.scss';
@import 'components/StackedBarChart/stackedBarChart.scss';
@import 'components/Card/card.scss';
@import 'components/Modal/modal.scss';
@import 'components/CheckBox/check-box.scss';
@import 'components/Avatar/avatar.scss';
@import 'components/Table/table.scss';
@import 'components/Swal/swal.scss';
@import 'components/CheckList/check-lists.scss';
@import 'components/Select/select.scss';
@import 'components/Progress/progress.scss';
@import 'components/Dashboard/dashboard.scss';
@import 'components/Menu/menu.scss';
@import 'components/DropDown/dropDown.scss';
@import 'components/Dropzone/dropzone.scss';
@import 'components/Switch/switch.scss';
@import 'components/Selector/selector.scss';
@import 'components/GridtableHeader/Header.scss';
@import 'components/BudgetPrint/BudgetPrint.scss';
@import 'components/PieceEdit/PieceEdit.scss';
@import 'components/homeButton/homeButton.scss';

//Screens
@import 'screens/Login/login-screen.scss';
@import 'screens/Vehicles/VehiclesScreen.scss';
@import 'screens/ModalClients/styles.scss';
// @import 'screens/Recovery/recovery-screen.scss';
@import 'screens/Sidebar/sidebar.scss';
@import 'screens/Dashboard/dashboard.scss';
@import 'screens/HeaderScreen/header-screen.scss';
@import 'screens/Client/clientsScreen.scss';
@import 'screens/Budget/BudgetScreen.scss';
@import 'screens/ModalBudget/ModalBudget.scss';
@import 'screens/Company/companyScreen.scss';
@import 'screens/Modules/modulesScreen.scss';
@import 'screens/Kanban/kanbanScreen.scss';
@import 'screens/BodyShop/BodyShopScreen.scss';
@import 'screens/ValueHourChart/ValueHourChartScreen.scss';
@import 'screens/ServiceType/ServiceTypeScreen.scss';
@import 'screens/Service/ServiceScreen.scss';
@import 'screens/ValueHourChart/ValueHourChartScreen.scss';
@import 'screens/ProductionPhases/ProductionPhasesScreen.scss';
@import 'screens/BudgetAdd/BudgetAddScreen.scss';
@import 'screens/Parts/PartsScreen.scss';
@import 'screens/ModalExternalBudget/ModalExternalBudget.scss';
@import 'screens/Register/RegisterScreen.scss';
@import 'screens/ManualVideo/ManualVideoScreen.scss';

//Container
// @import 'containers/Plan/plan.scss';
//iframe abrindo no meio da tela
body > iframe {
  display: none;
}

::-webkit-scrollbar-track {
  background: #70e000 !important;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background: #70e000;
}

::-webkit-scrollbar-thumb {
  // background-color: #5E5E66;
  background: black !important;
}

.container-app {
  display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;

  // background-color: #E5E5E5;

  @include mobile {
    padding-top: 60px;
  }

  .body-app {
    flex: 1;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    // overflow-y: scroll;
    background: linear-gradient(112.07deg, #0f0d0d 2.13%, #4b4b4d 99.26%);
    overflow-y: hidden !important;
    @include mobile {
      height: 100%;
      position: fixed;
      overflow: hidden;
      // margin-top: 40px;
      // height: calc(100vh - 70px);
    }
  }

  .admin-screen-content {
    overflow: hidden;
    display: flex;
    height: calc(100vh - 71px);

    @include mobile {
      height: calc(100vh - 100px);
    }
  }

  .admin-screen-main {
    flex: 1;
    overflow-y: hidden;
  }

  @include mobile {
    &.collapsed {
      flex-direction: column;
      display: block;
    }

    &.open {
      .body-app {
        display: none;
        overflow: hidden;
      }

      .admin-nav-avatar {
        display: none;
      }
    }
  }
}

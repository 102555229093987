.btn-swal {
  margin-inline: 10px;

  &.cancel-button {
    background-color: $secondary-color;
    color: white;

    &:hover {
      background-color: $secondary-color-dark !important;
    }
  }

  &.danger {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    &:focus {
      box-shadow: none; //0 0 0 0.25rem rgb(237 45 45 / 25%);
    }
  }

  &.confirm {
    background-color: $success-color-light;
    color: white;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(153 227 207 / 25%);
    }
  }
}

.swal2-input {
  height: 40px !important;
  font-size: 16px !important;
  margin: 0px !important;

  &:focus {
    border-color: $primary-color-dark !important;
  }
}

.swal2-container {
  z-index: 999999999 !important;
}

.swal2-warning {
  border-color: $error-color !important;
  color: $error-color !important;
}

.swal2-popup {
  background: #2d2d2e !important;
  border: 1px solid #3e3e40 !important;
}

.swal2-html-container {
  color: white !important;
}

.swal2-title {
  color: white !important;
}

.default-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 13px !important;
  font-weight: normal;
  height: 40px;
  display: flex;
  color: white;
  text-transform: uppercase;
  outline: 0;
  box-shadow: none;
  background-color: #67676a !important;

  .placeholder {
    color: #f7f7fc;
  }

  &:focus {
    border-color: $primary-color;
    color: white;
    outline: 0;
    box-shadow: none;
    background-color: #67676a !important;
  }
  &:disabled,
  &[readonly] {
    background-color: #b3b3b31c !important;
    font-weight: 500;
    opacity: 1;
  }
}

.search-icon {
  font-size: 14px;
  color: white;
  height: 40px;
  border-radius: 8px;
}

.icon-input-container {
  display: flex;
  flex-direction: column;
}

.icon-input {
  background-color: #67676a !important;
  // border-radius:8px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 0 !important;
  color: white;
  height: 40px;
  width: 125px !important;
}

#search::placeholder {
  color: white;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
}

.disabled-show {
  background-color: rgb(88 88 89) !important;
}

.auto-width {
  width: inherit;
}

.generic-input {
  .label {
    font-weight: 500;
    color: $text-body-color;
  }

  .hour-input {
    text-align: right;
  }

  .react-datepicker__input-container {
    z-index: 9999;

    input {
      border: 1px solid $line-color;
      border-radius: 6px;
      font-weight: normal;
      padding: 0.375rem 0.75rem;
      width: 100%;

      &:focus {
        color: #212529;
        background-color: #00000000 !important;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }
    }
  }

  &.select-input {
    .form-label {
      font-weight: 500;
      color: $text-body-color;
    }

    div[class*='-container'] {
      font-family: Poppins, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      text-overflow: ellipsis;

      div[class*='-control'] {
        border-color: $line-color;
        border-radius: 6px;
        min-height: 33.5px;

        &:not(:last-child) {
          border-color: #86b7fe;
        }
      }

      div[class*='-menu'] {
        z-index: 99999;
      }

      div[class*='-indicatorContainer'] {
        color: $text-placeholder-color;
        padding: 8px 8px 8px 4px;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      div[class*='-option'] {
        color: $text-label-color;
        font-size: 13px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
        cursor: pointer;
        padding: 2px 12px;
      }

      div[class*='-singleValue'] {
        font-size: 13px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
      }

      div[class*='-ValueContainer'] {
        overflow: overlay;
        height: 32px;

        div[class*='-placeholder'] {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      div[class*='-multiValue'] {
        font-size: 13px;
        font-weight: normal;
        font-family: Poppins, sans-serif;
        background-color: transparent;
        border: 1px solid $line-color;
        border-radius: 4px;
        padding-left: 5px;

        div {
          padding: 0px;

          &[role='button'] {
            padding-inline: 3px;
            background-color: transparent;
          }
        }
      }
    }
  }

  &.switch {
    display: flex;
    align-items: center;
    margin-top: 5px;

    > div {
      margin-left: 10px;
    }

    > label {
      margin-bottom: 0px;
    }
  }

  .button-add-table {
    background-color: #67676a !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: white;
      background-color: $primary-color;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-inline: 10px;
    }
  }
}

.radio-input {
  display: flex !important;
  width: 50%;

  .form-check-label {
    margin-left: 5px;
  }
}

.masked-input {
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  color: white;
  justify-content: center;
}

.money-label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: $text-title-color;
}

input {
  box-shadow: none !important;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  color: white;
  justify-content: center;
}

.input-group {
  ::placeholder {
    color: $text-label-color;
  }
}

*::-webkit-input-placeholder {
  color: $text-label-color;
}

.react-datepicker {
  .react-datepicker__navigation-icon::before {
    top: 13px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    background-color: $primary-color;
  }
}

.bodyshop-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: hidden;

  .bodyshop-info {
    display: flex;
    padding: 20px 25px;
    width: 100%;
    justify-content: end;
  }

  .bodyshop-body {
    background-color: #67676a;
    display: flex;
    // flex: 1 1;
    height: calc(100% - 15px);
    width: calc(100% - 30px);
    border-radius: 16px;
    padding: 10px;

    @include mobile {
      height: calc(100% - 40px);
      width: calc(100% - 3px);
      .rgt-text-truncate {
        font-size: 10px !important;
      }
    }
  }
}

.new-table-button {
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-radius: 5px;

  @include mobile {
    span {
      display: none;
    }
  }
}

.field-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field-actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.default-table-bodyshop {
  .rgt-wrapper {
    border: none;

    .rgt-header-container {
      background-color: transparent;
      border: none;

      .rgt-search-label,
      .rgt-search-icon,
      .rgt-columns-manager-wrapper {
        color: white;
      }

      .rgt-columns-manager-popover-title {
        color: #67676a;
      }

      .rgt-search-input {
        background-color: $line-color;
        padding: 1px 10px;
        border-radius: 8px;
        color: #5e5e66;
        margin-right: 0px;
      }
    }

    .rgt-container {
      background-color: transparent;
      // overflow-y: scroll;
      -ms-overflow-style: none;
      //scrollbar-width: none;

      .rgt-row-odd {
        background-color: #767676 !important;
      }
      .rgt-row-even {
        background-color: #565659 !important;
      }

      &::-webkit-scrollbar {
        // display: none;
        // width: 0px;
        // background-color: #5e5e66;
      }

      &::-webkit-scrollbar-thumb {
        // background-color: #5E5E66;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: #70e000;
        border-radius: 15px;
      }

      .rgt-cell-header {
        border: none;

        .rgt-cell-header-inner {
          background-color: #5e5e66;
          border: none;

          .rgt-text-truncate {
            color: $line-color;
            padding: 0px 0px;
            font-weight: 500;
          }
        }

        &:first-child {
          .rgt-cell-header-inner {
            border-top-left-radius: 16px !important;
            border-bottom-left-radius: 16px !important;
          }
        }

        &:nth-child(3) {
          .rgt-cell-header-inner {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0px 9px;
          }
        }
      }

      .rgt-cell {
        background-color: transparent;
        border: none;

        .rgt-text-truncate {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .rgt-footer {
      background-color: transparent;
      border: none;

      .rgt-footer-items-information,
      .rgt-footer-right-container {
        color: white;
      }

      .rgt-footer-page-size {
        align-items: center;
      }
    }
  }

  .icon-action {
    color: black !important;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.trash {
      background-color: #ffffff;
      color: #e82314 !important;

      &:hover &:focus {
        // background-color: $error-color-dark;
      }
    }

    &.send {
      height: 22px;
    }

    &:nth-child(1) {
      margin-right: 10px;
    }

    &:hover,
    &:focus {
      // background-color: #5E5E66;
      // opacity: 0.5;
    }
  }

  .icon-cell {
    background-color: #e4ffca;
    margin-left: 25px;
    border-radius: 15px;
  }

  .body-shop-icons {
    width: 18px;
    height: 18px;
    margin: 5px;
    color: #82ff05;
  }

  .rgt-footer-pagination-button {
    box-shadow: none;
    background-color: $primary-color-dark !important;
  }

  .rgt-disabled-button {
    background-color: $secondary-color-dark !important;
  }

  .rgt-search-highlight {
    background-color: rgba(219, 255, 235, 0.3);
  }

  .rgt-container-overlay {
    display: flex;
    text-align: center;
  }

  .image-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .not-image {
      width: 100%;
      height: 100%;
      background-color: #5e5e66;
    }
  }
}

.bodyshop-table {
  padding: 0px;

  > div {
    > div {
      justify-content: flex-end;
      padding-top: 0.9vh;
      padding-bottom: 0.1vh;
      display: flex;

      > label {
        display: flex;
        flex: 1;
        margin-bottom: 0px;
        align-items: center;
      }
    }
  }
}

// .rgt-text-truncate {
//     padding: 10px 10px !important;
// }

.admin-screen-main {
  > div {
    height: 100%;
  }
}

.dropdown-toggle {
  color: white;
  background-color: #67676a;
  min-width: 200px;
}

.route-title {
  flex-wrap: wrap;
  display: flex !important;

  @include mobile {
    padding-right: 10px;
    gap: 10px;
    display: block;
  }
}

.percentage-placement {
  display: inline-flex;
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 5px;

  @include mobile {
    padding-left: 20px;
  }
}

.percentage-placement-items {
  display: inline-flex;
  padding-top: 15px;
}

.percentage-button {
  color: white !important;
  border: 1px solid black !important;
  background-color: $primary-color !important;
  height: 40px !important;
  width: 50px !important;
  justify-content: center;
  align-items: center;
  display: flex;

  > span {
    margin: 0px !important;
    font-size: 20px !important;
  }
}

.percentage-item-description-button-placement {
  padding-top: 30px;
  padding-left: 13px;

  @include mobile {
    padding-left: 5px;
  }
}

.percentage-item-button-placement {
  padding-top: 5px;
  padding-left: 10px;

  @include mobile {
    padding-left: 0px;
    padding-top: 10px;
  }
}

.lower-div {
  // width: 67vw;
  padding-top: 25px;
}

.description-percentage-add {
  margin: 0px 5px 0px 5px !important;
  margin-bottom: 0rem !important;

  // display: inline-flex;
  // padding-top: 15px;
  > div {
    margin: 4px 5px 0px 0px !important;
  }
}

.service-type-placement-items {
  @include mobile {
    width: 42%;
  }

  > div {
    margin: 5px 5px 0px 0px !important;
  }
}

.div-name-percentage-description {
  width: 100%;
  margin-right: 20px;
  margin-left: 0px;
  margin-right: 10px;
  // flex: 1;

  > div {
    margin: 0px 5px 0px 0px !important;

    @include mobile {
      margin: 5px 0px 5px 5px !important;
      // width: 55vw;
    }
  }

  > div > div {
    // width: 48vw;

    @include mobile {
      // width: auto;
    }
  }

  @include mobile {
    margin-left: 0px;
  }
}

.div-name-percentage-items {
  padding-top: 15px;
  margin-left: 0px;
  margin-right: 0px;
  flex: 0.8;

  > div {
    @include mobile {
      margin: 5px 0px 5px 5px !important;
    }
  }

  @include mobile {
    margin-left: 0px;
  }
}

.div-checkbox-items {
  padding-top: 15px;
  margin-left: 0px;
  margin-right: 10px;
  flex: 0.8;

  > div > div {
    width: 50vw;
  }

  @include mobile {
    margin-left: 0px;
  }
}

.checkbox-placement {
  //padding-left: 5px;
  //padding-right: 5px;
  display: flex;
  align-items: center;
}

.checkbox-item {
  width: 18px;
  height: 18px;
  background-color: #82ff05;
  color: #82ff05;
  accent-color: #82ff05;

  &:active {
    color: #82ff05;
    background-color: #82ff05;
  }
}

.button-icon-delete {
  font-size: 15px;
  color: #ffaaa4 !important;
}

.percentage-delete-button {
  background-color: #ffaaa4 !important;
  height: 40px !important;
  width: 50px !important;
}

.bodyshop-title {
  color: white;
  font-size: 16px;
  font-weight: 600;

  &.step {
    display: flex;

    > div {
      padding: 0px !important;
      margin: 0px !important;
      flex: unset !important;
      // margin-left: 5px !important;
      width: 100%;

      > div {
        > input {
          height: 43px;
        }
      }
    }
  }
}
